import { useEffect, useState } from 'react';

import { PhoneCall } from '@styled-icons/boxicons-solid/PhoneCall';
import { Timeline } from '@styled-icons/fluentui-system-filled/Timeline';
import { CalendarHeartFill } from '@styled-icons/bootstrap/CalendarHeartFill';

const Carousel = ({ clickedSlide, slideDir }) => {
    const [currSections, setCurrSections] = useState([]);

    useEffect(() => {
        if (clickedSlide === false) {
            setCurrSections([
                {
                    step: 'One',
                    content: 'Review typeform document, have first call to discuss logistics. MVP delivered in one week.',
                    icon: <PhoneCall />
                },
                {
                    step: 'Two',
                    content: 'MVP feedback and setting timeline, budget, integrations.',
                    icon: <Timeline />
                }
            ]);
            return;
        };

        setCurrSections([
            {
                step: 'Two',
                content: 'MVP feedback and setting timeline, budget, integrations.',
                icon: <Timeline />
            },
            {
                step: 'Three',
                content: 'We deliver solutions within one month, and will refine the solution through out the life cycle of the agent.',
                icon: <CalendarHeartFill />
            }
        ]);
    }, [clickedSlide]);

    return (
        <div 
        className={`
            ${!slideDir ? 'carousel-slide-left' : slideDir && 'carousel-slide-right'}
            flex justify-evenly w-full h-full
        `}
        key={clickedSlide}>
            {
                currSections.length > 0 && currSections.map((section, i) => 
                    <div className='flex w-6/12 mx-2' index={i}>
                        <div className='flex justify-center items-center w-6/12'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="264" height="246" viewBox="0 0 264 246" fill="none">
                                <path opacity="0.08" d="M0 10L264 0V240L0 246V10Z" fill="white"/>
                            </svg>

                            <div className='w-20 absolute'>
                                { section.icon }
                            </div>
                        </div>

                        <div className='flex py-20 px-10 justify-center flex-wrap text-left w-6/12'>
                            <div className='w-full font-Sora font-[500] text-[1.4rem]'>
                                { 'Step ' + section.step + ':' }
                            </div>
                            
                            <div className='w-full opacity-50 leading-9 mt-4'>
                                { section.content }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default Carousel;