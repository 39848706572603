import FreeTile from "../components/FreeTile";
import BasicTile from "../components/BasicTile";
import ProTile from "../components/ProTile";
import ArticlesContainer from "./ArticlesContainer";

import { DashLg } from '@styled-icons/bootstrap/DashLg';

const PricingContainer = () => {
    return (
        <div className='flex justify-evenly flex-wrap w-full'>
            <div 
            style={{ backgroundImage: 'linear-gradient(to bottom, rgb(255, 255, 255) 60%, rgb(0, 0, 0) 40%)' }}
            className='flex justify-evenly flex-wrap w-full py-10'>
                <div className='flex justify-center flex-wrap w-full pb-20 px-4'>
                    <div className='font-[600] text-[1.2rem] py-2'>Affordable Pricings</div>

                    <div className='w-full text-center py-2'>
                        <DashLg 
                        className='w-12 opacity-20'
                        />
                    </div>

                    <div className='font-Sora font-[700] text-[4rem] text-center py-4'>
                        Pricing plans that suit your <br/> every need
                    </div>

                    <div className='w-full text-center text-[1.2rem] py-4 my-4 opacity-50'>
                        The most affordable AI solution solving your expensive problems
                    </div>
                </div>

                <FreeTile />
                <ProTile />
                <BasicTile />
            </div>

            <div className='lg:mr-80'>
                <ArticlesContainer />
            </div>
        </div>
    );
};

export default PricingContainer;