import PaymentLinkPro from './PaymentLinkPro.js';

import { CheckmarkCircle } from '@styled-icons/fluentui-system-filled/CheckmarkCircle';
import { StarOff } from '@styled-icons/fluentui-system-filled/StarOff';

const ProTile = () => {
    return (
        <div
        className='text-[1.2rem] px-8 py-6 rounded-md bg-[#0E1218] text-white md:mt-[-4vh] my-4'>
            <div className='border-b-2'>
                <div className='flex justify-between py-1'>
                    <div className='font-[700] font-Sora p-1'>
                        <StarOff className='w-6 mb-2 mr-2' />
                        PRO
                    </div>

                    <div className='bg-white text-black text-center rounded-3xl p-1 w-3/6 mb-2'>Most Popular</div>
                </div>
                <div className='opacity-50 pb-6 mt-[-1vh]'>Everything we offer</div>
                <div className='text-[2.5rem] font-[600] font-Sora py-1'>$24.99</div>
                <div className='opacity-50 pb-10'>per month</div>
            </div>

            <div className='pt-6 py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                Unlimited agents
            </div>

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                5000 chats per month in total with 
                <br/>
                <span className='ml-8'>all agents</span>
            </div>

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                Customizable judge
            </div>

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                Access to everything in the API
            </div>

            <div>
                <PaymentLinkPro />
            </div>
        </div>
    );
};

export default ProTile;