const MutualBotMask = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_158_408" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                <circle cx="28" cy="28" r="28" fill="#D9D9D9"/>
            </mask>

            <g mask="url(#mask0_158_408)">
                <circle cx="28" cy="28" r="28" fill="#279F71"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10.756 18.3752C10.8676 18.1226 11.1627 18.0082 11.4154 18.1197L11.2135 18.5771C11.4154 18.1197 11.4152 18.1196 11.4154 18.1197L11.4171 18.1204L11.4199 18.1217L11.4289 18.1257L11.4596 18.1399C11.4856 18.152 11.5225 18.1696 11.5688 18.1925C11.6614 18.2383 11.7921 18.3059 11.95 18.3949C12.2651 18.5727 12.692 18.8384 13.141 19.1917C14.0288 19.8902 15.0568 20.9737 15.4122 22.4438C15.4771 22.7123 15.3121 22.9824 15.0437 23.0473C14.7753 23.1122 14.5051 22.9472 14.4402 22.6788C14.1609 21.5234 13.3326 20.6148 12.5227 19.9776C12.1228 19.663 11.7405 19.4249 11.4586 19.2659C11.318 19.1866 11.2034 19.1274 11.1251 19.0887C11.086 19.0693 11.0561 19.0551 11.0368 19.0461L11.0157 19.0364L11.0116 19.0345C11.0112 19.0344 11.0115 19.0345 11.0116 19.0345C10.7591 18.9229 10.6446 18.6277 10.756 18.3752Z" fill="#0E1218"/>
                <path d="M9.99211 20.4787C11.3172 20.4787 12.3915 19.4045 12.3915 18.0794C12.3915 16.7542 11.3172 15.68 9.99211 15.68C8.66699 15.68 7.59277 16.7542 7.59277 18.0794C7.59277 19.4045 8.66699 20.4787 9.99211 20.4787Z" fill="#D2D4FF"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M43.7234 19.2343C43.7232 19.2344 43.7236 19.2342 43.7234 19.2343L43.5215 18.7769C43.7234 19.2343 43.7233 19.2344 43.7234 19.2343L43.7192 19.2362L43.6982 19.2459C43.6788 19.2549 43.6489 19.2692 43.6098 19.2885C43.5316 19.3272 43.4169 19.3864 43.2763 19.4657C42.9944 19.6247 42.6122 19.8628 42.2123 20.1774C41.4024 20.8146 40.574 21.7232 40.2947 22.8786C40.2298 23.147 39.9596 23.312 39.6912 23.2471C39.4228 23.1823 39.2578 22.9121 39.3227 22.6437C39.6781 21.1735 40.7061 20.09 41.5939 19.3915C42.0429 19.0382 42.4698 18.7725 42.7849 18.5947C42.9428 18.5057 43.0736 18.4381 43.1661 18.3923C43.2125 18.3694 43.2493 18.3518 43.2753 18.3397L43.3061 18.3255L43.315 18.3215L43.3179 18.3202L43.3188 18.3198C43.319 18.3197 43.3196 18.3195 43.5215 18.7769L43.3188 18.3198C43.5715 18.2083 43.8674 18.3224 43.9789 18.575C44.0903 18.8274 43.9756 19.1226 43.7234 19.2343Z" fill="#0E1218"/>
                <path d="M44.7441 20.6785C46.0692 20.6785 47.1434 19.6043 47.1434 18.2792C47.1434 16.9541 46.0692 15.8799 44.7441 15.8799C43.4189 15.8799 42.3447 16.9541 42.3447 18.2792C42.3447 19.6043 43.4189 20.6785 44.7441 20.6785Z" fill="#D2D4FF"/>
                <path d="M14.3883 24.9417H10.9471C7.94629 24.9417 5.51367 27.3743 5.51367 30.3751C5.51367 33.3759 7.94629 35.8085 10.9471 35.8085H14.3883C17.3891 35.8085 19.8217 33.3759 19.8217 30.3751C19.8217 27.3743 17.3891 24.9417 14.3883 24.9417Z" fill="#D2D4FF"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M8.099 25.2385C8.36064 25.1502 8.64433 25.2907 8.73266 25.5523L8.25893 25.7123C8.73266 25.5523 8.7326 25.5522 8.73266 25.5523L8.73332 25.5543L8.73457 25.5581L8.73876 25.5708L8.75354 25.6168C8.76608 25.6564 8.7838 25.7138 8.80551 25.7877C8.84892 25.9354 8.9083 26.1493 8.97399 26.4193C9.10528 26.9589 9.26229 27.7247 9.36696 28.6355C9.57561 30.4511 9.58006 32.8752 8.72936 35.2377C8.6358 35.4975 8.34935 35.6323 8.08953 35.5387C7.82972 35.4452 7.69494 35.1587 7.78849 34.8989C8.56794 32.7343 8.57238 30.4803 8.3735 28.7497C8.2744 27.8874 8.12576 27.163 8.00234 26.6557C7.94067 26.4023 7.88544 26.2036 7.84606 26.0695C7.82638 26.0025 7.81068 25.9518 7.80013 25.9184L7.78834 25.8817L7.78563 25.8735L7.78519 25.8722C7.69702 25.6106 7.83744 25.3268 8.099 25.2385Z" fill="#0E1218"/>
                <path d="M43.175 36.1706H39.7337C36.7349 36.1706 34.3003 33.736 34.3003 30.7372C34.3003 27.7383 36.7349 25.3038 39.7337 25.3038H43.175C46.1739 25.3038 48.6084 27.7383 48.6084 30.7372C48.6084 33.736 46.1739 36.1706 43.175 36.1706Z" fill="#D2D4FF"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M46.3341 26.2351L46.3335 26.2367L46.3308 26.2449L46.319 26.2816C46.3085 26.3149 46.2928 26.3657 46.2731 26.4327C46.2337 26.5667 46.1785 26.7654 46.1168 27.0189C45.9934 27.5262 45.8448 28.2506 45.7457 29.1128C45.5468 30.8434 45.5512 33.0974 46.3307 35.2621C46.4242 35.5219 46.2895 35.8084 46.0296 35.9019C45.7698 35.9955 45.4834 35.8607 45.3898 35.6009C44.5391 33.2383 44.5436 30.8143 44.7522 28.9987C44.8569 28.0878 45.0139 27.3221 45.1452 26.7825C45.2109 26.5125 45.2703 26.2986 45.3137 26.1508C45.3354 26.0769 45.3531 26.0195 45.3656 25.9799L45.3804 25.9339L45.3846 25.9212L45.3859 25.9175L45.3863 25.9163C45.3863 25.9161 45.3865 25.9155 45.8603 26.0754L45.3863 25.9163C45.4746 25.6546 45.7585 25.5134 46.0202 25.6017C46.2817 25.69 46.4222 25.9736 46.3341 26.2351Z" fill="#0E1218"/>
                <path d="M31.9567 17.3353H23.0808C16.5795 17.3353 11.3091 22.6057 11.3091 29.1071V29.1083C11.3091 35.6097 16.5795 40.88 23.0808 40.88H31.9567C38.4581 40.88 43.7285 35.6097 43.7285 29.1083V29.1071C43.7285 22.6057 38.4581 17.3353 31.9567 17.3353Z" fill="white"/>
                <path d="M15.1947 22.3012C17.2723 23.6171 19.9293 23.7729 22.2997 23.3745C24.6123 22.9862 27.0016 22.0536 28.6896 20.3794C28.7197 20.3493 28.77 20.343 28.8065 20.3644C30.8011 21.5546 33.0974 22.4621 35.4615 22.2471C37.1369 22.095 38.5773 21.3761 39.8015 20.3518C37.7151 18.4791 34.9626 17.3353 31.9549 17.3353H23.0802C19.517 17.3353 16.3095 18.9403 14.1465 21.4628C14.462 21.7771 14.8114 22.0586 15.1947 22.3012Z" fill="#D2D4FF"/>
                <path d="M35.334 24.1437H20.1826C16.8319 24.1437 14.1157 26.8599 14.1157 30.2105C14.1157 33.5612 16.8319 36.2774 20.1826 36.2774H35.334C38.6846 36.2774 41.4008 33.5612 41.4008 30.2105C41.4008 26.8599 38.6846 24.1437 35.334 24.1437Z" fill="#0E1218"/>
                <path d="M19.7729 34.2023C20.7981 34.2023 21.6293 33.3712 21.6293 32.3459C21.6293 31.3207 20.7981 30.4896 19.7729 30.4896C18.7476 30.4896 17.9165 31.3207 17.9165 32.3459C17.9165 33.3712 18.7476 34.2023 19.7729 34.2023Z" fill="#CCC5B8"/>
                <path d="M37.4174 32.7598C37.6545 31.7623 37.038 30.7615 36.0406 30.5244C35.0431 30.2874 34.0423 30.9038 33.8052 31.9012C33.5682 32.8987 34.1846 33.8995 35.182 34.1366C36.1795 34.3736 37.1803 33.7572 37.4174 32.7598Z" fill="#CCC5B8"/>
            </g>
        </svg>
    );
};

export default MutualBotMask;