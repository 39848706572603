import { createSlice } from '@reduxjs/toolkit'

const initialChatsState = {
	messages: [],
	id: 1
};

const chatsSlice = createSlice({
	name: 'chats',
	initialState: initialChatsState,
	reducers: {
		addMessage(state, action) {
			state.messages.push(action.payload);
			state.id = state.id + 1;
		},
		updateMessage(state, action) {
			const { id, newContent, thoughts, thought_id_count, points_str, points,
					user, character_response, prompt_stats, relevant_prompt, relevant_information,
					mood_prompt, character_prompt, prompt, thought_prompt, latest_mood,
					latest_mood_points} = action.payload;
			const existingMessage = state.messages.find(message => message.id === id);
			if (newContent) {
				existingMessage.content = newContent;
			}
			if (thoughts) {
				existingMessage.thoughts = thoughts;
			}
			if (thought_id_count){
				existingMessage.thought_id = thought_id_count;
			}
			if (points_str){
				existingMessage.points_str = points_str;
			}
			if (points) {
				existingMessage.points = points;
			}
			if(user){
				existingMessage.user = user
			}
			if(character_response){
				existingMessage.character_response = character_response
			}
			if(prompt_stats){
				existingMessage.prompt_stats = prompt_stats
			}
			if(relevant_prompt){
				existingMessage.relevant_prompt = relevant_prompt
			}
			if(relevant_information){
				existingMessage.relevant_information = relevant_information
			}
			if(mood_prompt){
				existingMessage.mood_prompt = mood_prompt
			}
			if(character_prompt){
				existingMessage.character_prompt = character_prompt
			}
			if(prompt){
				existingMessage.prompt = prompt
			}
			if(thought_prompt){
				existingMessage.thought_prompt = thought_prompt
			}
			if(latest_mood){
				existingMessage.latest_mood = latest_mood
			}
			if(latest_mood_points){
				existingMessage.latest_mood_points = latest_mood_points
			}
		},
		clearMessages(state) {
			state.messages = [];
			state.id = 1;
		},
	}
})

export const { addMessage, updateMessage, clearMessages } = chatsSlice.actions

export default chatsSlice.reducer
