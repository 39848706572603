import { useSelector } from "react-redux";

import SplashPage from "./SplashPage"

// Serves as the main container for all of our components
const BaseContainer = () => {
    const menu = useSelector(state => state.menu);

    return (
        <div className="h-full mt-16">
            <div className={`${menu.clickedHome ? 'block' : 'hidden'}`}>
                <SplashPage />
            </div>
        </div>
    );
};

export default BaseContainer;