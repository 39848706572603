import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import MutualBotMask from "./MutualBotMask";

const Message = ({ id, user, msg }) => {
    const messages = useSelector(state => state.mutualBot.messages);

    const [maxId, setMaxId] = useState(0);

    /*
    The below useEffect runs each time a new message is added to the Redux store and sets reassigns a slice of state,
    which points to the id of the latest message. This id allows us to display the 'MutualBotMask' SVG component
    on the latest MutualBot reply/only that reply.
    */
    useEffect(() => {
        let max = maxId;
        messages.forEach(msg => { if (msg.id > maxId) max = msg.id });

        setMaxId(max);
    }, [messages]);

    return (
        <div className={`${user === 'user' ? 'self-end' : 'self-start'}`}>
            <div className="px-4 flex flex-col justify-end relative">
                <div className={`${user === 'assistant' && (msg && msg.length > 0) ? 'flex self-start absolute bottom-0 left-0' : 'hidden'}`}>
                    {/* If the current message is the latest in our Redux store, we display the following SVG component. */}
                    <div className={`${id === maxId ? 'visible' : 'invisible'}`}>
                        <MutualBotMask />
                    </div>
                </div>

                <div className={`
                    ${
                        user === 'assistant' ?
                        'bg-assistant-message rounded-tl-3xl rounded-tr-3xl rounded-br-3xl ml-14' :
                        'bg-user-message rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl mr-4'
                    }
                    max-w-[50vw] p-4 text-white mt-4
                `}>
                                        {/* { msg } */}
                {msg.split('\n').map((line, index) => (
                    <p key={index}>{line}
                    <br/>
                    </p>
                ))}
                </div>
            </div>
        </div>
    );
};

export default Message;