import { useSelector, useDispatch } from "react-redux";

import fetchPresetMessage from '../../controller/fetchPresetMessage.js';

import MutualBotMask from "./MutualBotMask";

const PresetMessages = () => {
    const dispatch = useDispatch();

    const curNPC = useSelector((state) => state.mutualBot.curNPC);
    let currentId = useSelector((state) => state.mutualBot.id);

    return (
        <div className='flex flex-col justify-center items-center w-full flex-wrap mt-6 w-full'>
            <div className="flex justify-center w-full flex-wrap">
                <MutualBotMask />
            </div>

            <div className="text-center text-white mt-2">I am frequently asked:</div>

            <div className="flex justify-center items-center flex-wrap flex-col h-56 mt-4">
                <div
                onClick={() => {
                    // Calls the fetchPresetMessage function to send the user input and receive a response
                    fetchPresetMessage('What is Mutual?', dispatch, currentId, curNPC);
                }}
                className="w-[11.2em] h-14 rounded-full border border-transparent text-white bg-preset-message flex-shrink-0 flex items-center justify-center m-auto cursor-pointer">
                    <span className="opacity-50">What is Mutual?</span>
                </div>

                <div
                onClick={() => {
                    // Calls the fetchPresetMessage function to send the user input and receive a response
                    fetchPresetMessage('How can I use Mutual for my dropshipping business?', dispatch, currentId, curNPC);
                }}
                className="w-[16em] h-14 rounded-full border border-transparent text-white bg-preset-message flex-shrink-0 flex items-center justify-center m-auto cursor-pointer text-center">
                    <span className="opacity-50">How can I use Mutual for my dropshipping business?</span>
                </div>

                <div
                onClick={() => {
                    // Calls the fetchPresetMessage function to send the user input and receive a response
                    fetchPresetMessage('What are some frequently asked questions?', dispatch, currentId, curNPC);
                }}
                className="w-[20em] h-14 rounded-full border border-transparent text-white bg-preset-message flex-shrink-0 flex items-center justify-center m-auto cursor-pointer text-center">
                    <span className="opacity-50">What are some frequently asked questions?</span>
                </div>
            </div>
        </div>
    );
};

export default PresetMessages;
