import { ReactComponent as Green_logo } from '../../assets/icons/green_logo.svg';

import '../index.css';

const Footer = () => {
    return (
        <div id='footer-container' className='flex flex-col text-white px-[4.5vw] space-y-[2vh] h-fit py-8'>
            <div className="flex h-[7vh] justify-between w-full text-base pt-[2.5vh]">
                <div
                className="flex justify-center items-center">
                    <Green_logo className="w-[2vw] h-[3vh] mr-[0.5vw]" />
                    <div className="font-semibold text-[1.3rem]">
                    MUTUAL</div>
                </div>
                <div className="flex-right flex space-x-[1vw] items-center invisible">
                    <button className="text-black bg-white w-[160px] h-[52px] text-center
                    rounded-md cursor-pointer font-[600] text-[0.9rem]"
                    >Register Now</button>
                </div>

            </div>

            <div className='border-b-2 w-full border-gray-800 py-2'>
            </div>
            <div className='flex justify-between items-center pt-[0.5rem]'>
                <p className='text-[#FFF] text-[1rem] font-[100] opacity-[0.5]'>ⓒ 2023 Mutual. All rights reserved</p>
                <div className='flext text-[#FFF] opacity-[0.5] space-x-[1.5rem]'>
                    {/* <a href="" target="_blank" className="fa-brands fa-twitter fa-xl"></a>
                    <a href="" target="_blank" className="fa-brands fa-facebook fa-xl"></a>
                    <a href="" target="_blank" className="fa-brands fa-instagram fa-xl"></a>
                    <a href="" target="_blank" className="fa-brands fa-linkedin fa-xl"></a>
                    <a href="" target="_blank" className="fa-brands fa-github fa-xl"></a> */}
                    <a href="https://twitter.com/trymutual" target='_blank' className="fa-brands fa-twitter fa-xl"></a>
                    {/* <p className="fa-brands fa-facebook fa-xl"></p> */}
                    {/* <p className="fa-brands fa-instagram fa-xl"></p> */}
                    <a href="https://www.linkedin.com/company/mutualai/" target='_blank' className="fa-brands fa-linkedin fa-xl"></a>
                    <a href="https://discord.com/invite/vQNsUbSQxg" target='_blank' className="fa-brands fa-discord fa-xl"></a>
                    {/* <p className="fa-brands fa-github fa-xl"></p> */}
                </div>
            </div>
        </div>
    );
};

export default Footer;