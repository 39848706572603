import { useNavigate } from 'react-router-dom';

import { KeyboardArrowRight } from '@styled-icons/material/KeyboardArrowRight';

const EnterpriseSolutions = () => {
    const navigate = useNavigate();

    return (
        <div
        className='flex justify-center items-center flex-wrap py-36 font-Inter'>
            <div className='w-full px-20'>
                <div className='font-Sora font-[700] text-[3.5rem] w-full'>
                    Mutual can help with:
                </div>

                <div className='mt-4 opacity-50 text-[1.3rem] w-full'>
                    Streamlining operations, enhancing customer satisfaction, and improving efficiency with custom AI agents.
                </div>
            </div>

            <div 
            onClick={() => navigate('/ggd')}
            className='font-Inter font-[600] mt-14 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="225" height="64" viewBox="0 0 225 64" fill="#0E1218">
                    <path d="M0 2.05404C0 0.928592 0.928264 0.0247537 2.05331 0.0547551L223.053 5.94809C224.137 5.97698 225 6.86357 225 7.94738V56.0526C225 57.1364 224.137 58.023 223.053 58.0519L2.05331 63.9452C0.928261 63.9752 0 63.0714 0 61.946V2.05404Z" fill="white"/>
                    <path d="M0.75 2.05404C0.75 1.35064 1.33016 0.785738 2.03332 0.804489L223.033 6.69782C223.71 6.71588 224.25 7.27 224.25 7.94738V56.0526C224.25 56.73 223.71 57.2841 223.033 57.3022L2.03332 63.1955C1.33016 63.2143 0.75 62.6494 0.75 61.946V2.05404Z" stroke="#21222D" stroke-opacity="0.1" stroke-width="1.5"/>
                    <text x="112.5" y="37" fill="white" font-size="16px" text-anchor="middle">Learn more about Mutual</text>
                </svg>

                <div className='flex justify-end relative bottom-6 left-2'>
                    <div className='bg-assistant-message rounded-sm w-6'>
                        <KeyboardArrowRight />
                    </div>
                </div>
            </div>

            <div className='flex justify-evenly w-full mt-28 pb-8'>
                <div className='mt-[-6vh] w-[20vw]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="304" height="306" viewBox="0 0 304 306" fill="none">
                        <path d="M0 2.06699C0 0.936608 0.936092 0.0308432 2.06586 0.0680679L301.566 9.93627C302.644 9.9718 303.5 10.8563 303.5 11.9352V303.973C303.5 305.088 302.588 305.988 301.474 305.973L1.97364 302.026C0.879443 302.012 0 301.12 0 300.026V2.06699Z" fill="#279F71"/>

                        <svg xmlns="http://www.w3.org/2000/svg" x="20" y="45" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path opacity="0.2" d="M21.2009 8.29974V11.9997H18.241C15.021 11.9997 13.401 13.6197 13.401 16.8397V35.9997H8.30102C5.44103 35.9997 4.00098 34.5596 4.00098 31.6996V8.29974C4.00098 5.43974 5.44103 3.99969 8.30102 3.99969H16.901C19.761 3.99969 21.2009 5.43974 21.2009 8.29974Z" fill="white"/>
                            <path d="M34.7404 16.84V39.16C34.7404 42.38 33.1404 44 29.9204 44H18.2404C15.0204 44 13.4004 42.38 13.4004 39.16V16.84C13.4004 13.62 15.0204 12 18.2404 12H29.9204C33.1404 12 34.7404 13.62 34.7404 16.84Z" fill="white"/>
                            <path opacity="0.2" d="M43.9988 8.29974V31.6996C43.9988 34.5596 42.5587 35.9997 39.6987 35.9997H34.7388V16.8397C34.7388 13.6197 33.1388 11.9997 29.9188 11.9997H26.7988V8.29974C26.7988 5.43974 28.2388 3.99969 31.0988 3.99969H39.6987C42.5587 3.99969 43.9988 5.43974 43.9988 8.29974Z" fill="white"/>
                            <path d="M27.999 23.4997H19.999C19.179 23.4997 18.499 22.8197 18.499 21.9997C18.499 21.1797 19.179 20.4997 19.999 20.4997H27.999C28.819 20.4997 29.499 21.1797 29.499 21.9997C29.499 22.8197 28.819 23.4997 27.999 23.4997Z" fill="#279F71"/>
                            <path d="M27.999 29.4997H19.999C19.179 29.4997 18.499 28.8197 18.499 27.9997C18.499 27.1797 19.179 26.4997 19.999 26.4997H27.999C28.819 26.4997 29.499 27.1797 29.499 27.9997C29.499 28.8197 28.819 29.4997 27.999 29.4997Z" fill="#279F71"/>
                            <path d="M25.5 38.0003V44.0003H22.5V38.0003C22.5 37.1803 23.18 36.5003 24 36.5003C24.82 36.5003 25.5 37.1803 25.5 38.0003Z" fill="#279F71"/>
                        </svg>

                        <text x="150" y="158" fill="white" text-anchor="middle" font-family="Sora" font-weight='600' font-size='1.4rem'>
                            Limitless Possibilities
                        </text>

                        <text x="148" y="200" fill="white" text-anchor="middle" font-family="Inter" font-size='1rem'>
                            Explore a vast array of innovative
                        </text>

                        <text x="141" y="230" fill="white" text-anchor="middle" font-family="Inter" font-size='1rem'>
                            applications, unlocking the true
                        </text>

                        <text x="128" y="260" fill="white" text-anchor="middle" font-family="Inter" font-size='1rem'>
                            potential and diversity of AI.
                        </text>
                    </svg>
                </div>

                <div className='w-[20vw]'>
                    <svg xmlns="http://www.w3.org/2000/svg" x="45" y="40" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M30.4155 44.2705H24.478V38.333C24.478 37.4788 23.7697 36.7705 22.9155 36.7705C22.0614 36.7705 21.353 37.4788 21.353 38.333V44.2705H15.4155C14.5614 44.2705 13.853 44.9788 13.853 45.833C13.853 46.6871 14.5614 47.3955 15.4155 47.3955H30.4155C31.2697 47.3955 31.978 46.6871 31.978 45.833C31.978 44.9788 31.2697 44.2705 30.4155 44.2705Z" fill="#D2D4FF"/>
                        <path d="M41.6675 25.4171V28.9588C41.6675 35.5213 37.9175 38.3338 32.2925 38.3338H13.5425C7.91748 38.3338 4.16748 35.5213 4.16748 28.9588V17.7087C4.16748 11.1462 7.91748 8.33374 13.5425 8.33374H19.1675C18.8966 9.12541 18.7508 10.0004 18.7508 10.9379V19.063C18.7508 21.0838 19.4175 22.7921 20.605 23.9796C21.7925 25.1671 23.5008 25.8338 25.5216 25.8338V28.7295C25.5216 29.792 26.73 30.4379 27.6258 29.8546L33.6466 25.8338H39.0633C40.0008 25.8338 40.8758 25.6879 41.6675 25.4171Z" fill="#878DFF"/>
                        <path d="M45.8333 10.9371V19.0621C45.8333 22.1663 44.25 24.4996 41.6667 25.4163C40.875 25.6871 40 25.833 39.0625 25.833H33.6458L27.625 29.8538C26.7292 30.4371 25.5208 29.7912 25.5208 28.7287V25.833C23.5 25.833 21.7917 25.1663 20.6042 23.9788C19.4167 22.7913 18.75 21.083 18.75 19.0621V10.9371C18.75 9.99959 18.8958 9.12459 19.1667 8.33293C20.0833 5.74959 22.4167 4.16626 25.5208 4.16626H39.0625C43.125 4.16626 45.8333 6.87459 45.8333 10.9371Z" fill="#D2D4FF"/>
                        <path d="M32.1675 16.6663C31.2925 16.6663 30.605 15.9579 30.605 15.1038C30.605 14.2496 31.3133 13.5413 32.1675 13.5413C33.0216 13.5413 33.73 14.2496 33.73 15.1038C33.73 15.9579 33.0216 16.6663 32.1675 16.6663Z" fill="#878DFF"/>
                        <path d="M38.02 16.6663C37.145 16.6663 36.4575 15.9579 36.4575 15.1038C36.4575 14.2496 37.1659 13.5413 38.02 13.5413C38.8742 13.5413 39.5825 14.2496 39.5825 15.1038C39.5825 15.9579 38.8742 16.6663 38.02 16.6663Z" fill="#878DFF"/>
                        <path d="M26.292 16.6663C25.417 16.6663 24.7295 15.9579 24.7295 15.1038C24.7295 14.2496 25.4378 13.5413 26.292 13.5413C27.1462 13.5413 27.8545 14.2496 27.8545 15.1038C27.8545 15.9579 27.167 16.6663 26.292 16.6663Z" fill="#878DFF"/>
                    </svg>

                    <div className='font-[600] text-[1.4rem] mt-10 w-fit font-Sora'>Customization</div>

                    <div className='mt-4 opacity-70 w-[20vw] text-left absolute leading-[200%]'>
                        It's easy as ever to customize your agents with mutual. All you have to do is assign a basic prompt and feed it memory through our API or dashboard. The agent will talk and behave as you wish.
                    </div>
                </div>

                <div className='w-[20vw]'>
                    <svg xmlns="http://www.w3.org/2000/svg" x="43" y="40" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path opacity="0.2" d="M22.0841 8.64548V12.4996H19.0008C15.6466 12.4996 13.9592 14.1871 13.9592 17.5412V37.4996H8.6467C5.66753 37.4996 4.16748 35.9995 4.16748 33.0204V8.64548C4.16748 5.66631 5.66753 4.16626 8.6467 4.16626H17.605C20.5842 4.16626 22.0841 5.66631 22.0841 8.64548Z" fill="#878DFF"/>
                        <path d="M36.1881 17.5416V40.7917C36.1881 44.1459 34.5215 45.8333 31.1673 45.8333H19.0006C15.6464 45.8333 13.959 44.1459 13.959 40.7917V17.5416C13.959 14.1875 15.6464 12.5 19.0006 12.5H31.1673C34.5215 12.5 36.1881 14.1875 36.1881 17.5416Z" fill="#878DFF"/>
                        <path opacity="0.2" d="M45.8321 8.64548V33.0204C45.8321 35.9995 44.3321 37.4996 41.3529 37.4996H36.1863V17.5412C36.1863 14.1871 34.5197 12.4996 31.1655 12.4996H27.9155V8.64548C27.9155 5.66631 29.4155 4.16626 32.3946 4.16626H41.3529C44.3321 4.16626 45.8321 5.66631 45.8321 8.64548Z" fill="#878DFF"/>
                        <path d="M29.1659 24.4788H20.8325C19.9784 24.4788 19.27 23.7704 19.27 22.9163C19.27 22.0621 19.9784 21.3538 20.8325 21.3538H29.1659C30.02 21.3538 30.7284 22.0621 30.7284 22.9163C30.7284 23.7704 30.02 24.4788 29.1659 24.4788Z" fill="#E9F5F1"/>
                        <path d="M29.1659 30.7288H20.8325C19.9784 30.7288 19.27 30.0204 19.27 29.1663C19.27 28.3121 19.9784 27.6038 20.8325 27.6038H29.1659C30.02 27.6038 30.7284 28.3121 30.7284 29.1663C30.7284 30.0204 30.02 30.7288 29.1659 30.7288Z" fill="#E9F5F1"/>
                        <path d="M26.5625 39.5837V45.8337H23.4375V39.5837C23.4375 38.7296 24.1458 38.0212 25 38.0212C25.8542 38.0212 26.5625 38.7296 26.5625 39.5837Z" fill="#E9F5F1"/>
                    </svg>

                    <div className='font-[600] text-[1.4rem] mt-10 w-fit font-Sora'>Shareability</div>

                    <div className='mt-4 opacity-70 w-[20vw] text-left absolute leading-[200%]'>
                        You can easily share and put your agents anywhere you'd like through our website embedding and QR codes that lead directly to our agents. With this, you can generate an AI assistance accessible right in your website or even in the physical world.
                    </div>
                </div>

                <div className='w-[20vw]'>
                    <svg xmlns="http://www.w3.org/2000/svg" x="42" y="40" width="50" height="50" viewBox="0 0 50 50" fill="none">
                        <path d="M45.1252 21.75L43.0835 30.4583C41.3335 37.9792 37.8752 41.0208 31.3752 40.3958C30.3335 40.3125 29.2085 40.125 28.0002 39.8333L24.5002 39C15.8127 36.9375 13.1252 32.6458 15.1669 23.9375L17.2085 15.2083C17.6252 13.4375 18.1252 11.8958 18.7502 10.625C21.1877 5.58333 25.3335 4.22916 32.2919 5.875L35.771 6.6875C44.5002 8.72916 47.1669 13.0417 45.1252 21.75Z" fill="#878DFF"/>
                        <path d="M31.3752 40.3958C30.0836 41.2708 28.4586 42 26.4794 42.6458L23.1877 43.7292C14.9169 46.3958 10.5627 44.1667 7.87522 35.8958L5.20856 27.6667C2.54189 19.3958 4.75022 15.0208 13.0211 12.3542L16.3127 11.2708C17.1669 11 17.9794 10.7708 18.7502 10.625C18.1252 11.8958 17.6252 13.4375 17.2086 15.2083L15.1669 23.9375C13.1252 32.6458 15.8127 36.9375 24.5002 39L28.0002 39.8333C29.2086 40.125 30.3336 40.3125 31.3752 40.3958Z" fill="#D2D4FF"/>
                        <path d="M36.4374 21.8959C36.3124 21.8959 36.1874 21.875 36.0415 21.8542L25.9374 19.2917C25.104 19.0834 24.604 18.2292 24.8124 17.3959C25.0207 16.5625 25.8749 16.0625 26.7082 16.2709L36.8124 18.8334C37.6457 19.0417 38.1457 19.8959 37.9374 20.7292C37.7707 21.4167 37.1249 21.8959 36.4374 21.8959Z" fill="#F3F4FF"/>
                        <path d="M30.3332 28.9375C30.2082 28.9375 30.0832 28.9167 29.9374 28.8959L23.8749 27.3542C23.0415 27.1459 22.5415 26.2917 22.7499 25.4584C22.9582 24.625 23.8124 24.125 24.6457 24.3334L30.7082 25.875C31.5415 26.0834 32.0415 26.9375 31.8332 27.7709C31.6665 28.4792 31.0415 28.9375 30.3332 28.9375Z" fill="#F3F4FF"/>
                    </svg>

                    <div className='font-[600] text-[1.4rem] mt-10 w-fit font-Sora'>Safety</div>

                    <div className='mt-4 opacity-70 w-[20vw] text-left absolute leading-[200%]'>
                        Our built-in judge systems make sure that malicious users cannot break your agents. Even in internet games where there may be trolls trying to manipulate your agents, they will be safe and in-character.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnterpriseSolutions;