import StatsImg from '../../assets/stats_img.png'

const EnterpriseStats = () => {
    return (
        <div
        className='flex justify-between flex-wrap py-28 p-20 lg:h-screen'>
            <div className='font-Sora text-[3rem] text-left font-[600] w-full lg:w-7/12 mt-28'>
                Why Mutual is the best choice
                <br/>
                for your enterprise

                <div className='text-[1rem] opacity-50 text-left font-[400] mt-6 leading-10 w-full'>
                    Streamline operations, enhance customer satisfaction, and improve 
                    <br/>
                    efficiency with custom AI agents. From providing instant support to 
                    <br/>
                    offering personalized recommendations, Mutual has you covered.
                </div>
            </div>

            <div className='flex justify-end w-full lg:w-5/12'>
                <img src={StatsImg} alt="Stats Image" className='rounded-lg absolute right-28' />

                {/* <div className='relative bottom-20 right-14'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="317" height="318" viewBox="0 0 317 318" fill="none">
                        <path d="M0 4.39643C0 2.03841 2.02934 0.192051 4.37687 0.414214L313.377 29.6571C315.431 29.8515 317 31.5762 317 33.6393V294.244C317 296.356 315.359 298.104 313.252 298.236L4.25187 317.732C1.94823 317.877 0 316.048 0 313.74V4.39643Z" fill="#279F71"/>
                        <text x="148" y="160" fill="white" font-family="Sora" font-size="23px" font-weight="700" text-anchor="middle">
                            Trusted by the best
                        </text>

                        <svg xmlns="http://www.w3.org/2000/svg" x='30' y='50' width="44" height="44" viewBox="0 0 44 44" fill="none">
                            <path opacity="0.2" d="M19.4346 7.60773V10.9993H16.7213C13.7696 10.9993 12.2847 12.4843 12.2847 15.436V32.9993H7.60968C4.98801 32.9993 3.66797 31.6793 3.66797 29.0576V7.60773C3.66797 4.98606 4.98801 3.66602 7.60968 3.66602H15.493C18.1147 3.66602 19.4346 4.98606 19.4346 7.60773Z" fill="white"/>
                            <path d="M31.8458 15.4366V35.8967C31.8458 38.8484 30.3792 40.3333 27.4275 40.3333H16.7208C13.7691 40.3333 12.2842 38.8484 12.2842 35.8967V15.4366C12.2842 12.485 13.7691 11 16.7208 11H27.4275C30.3792 11 31.8458 12.485 31.8458 15.4366Z" fill="white"/>
                            <path opacity="0.2" d="M40.3316 7.60773V29.0576C40.3316 31.6793 39.0115 32.9993 36.3899 32.9993H31.8432V15.436C31.8432 12.4843 30.3766 10.9993 27.4249 10.9993H24.5649V7.60773C24.5649 4.98606 25.8849 3.66602 28.5065 3.66602H36.3899C39.0115 3.66602 40.3316 4.98606 40.3316 7.60773Z" fill="white"/>
                            <path d="M25.6654 21.541H18.332C17.5804 21.541 16.957 20.9177 16.957 20.166C16.957 19.4143 17.5804 18.791 18.332 18.791H25.6654C26.417 18.791 27.0404 19.4143 27.0404 20.166C27.0404 20.9177 26.417 21.541 25.6654 21.541Z" fill="#279F71"/>
                            <path d="M25.6654 27.041H18.332C17.5804 27.041 16.957 26.4177 16.957 25.666C16.957 24.9143 17.5804 24.291 18.332 24.291H25.6654C26.417 24.291 27.0404 24.9143 27.0404 25.666C27.0404 26.4177 26.417 27.041 25.6654 27.041Z" fill="#279F71"/>
                            <path d="M23.375 34.834V40.334H20.625V34.834C20.625 34.0823 21.2483 33.459 22 33.459C22.7517 33.459 23.375 34.0823 23.375 34.834Z" fill="#279F71"/>
                        </svg>
                    </svg>
                </div> */}
            </div>
        </div>
    );
};

export default EnterpriseStats;