import { createSlice } from '@reduxjs/toolkit'
import { generateRandomUser } from '../../utils/randomFunc';
import { getRandomNPC } from '../../utils/randomFunc';

// Define the initial state.
const initialChatsState = {
	messages: [],
	id: 1,
	curUser: generateRandomUser(),
	curNPC: getRandomNPC()
};

// Use createSlice to generate the reducer and the action creators.
const mutualBotChatSlice = createSlice({
	// The name is used as part of the action type.
	name: 'mutualBotSlice',
	// Initial state for this slice of the Redux store.
	initialState: initialChatsState,
	// Define the reducer functions, which are dispatched in various components in order to alter the current state.
	reducers: {
		/*
        Each function we define in this object will receive its own action type as applicable,
        and we can use these functions to handle the actions.
        The name of each function will be used as the part of the action type,
        and will be available as a function on the resulting action creator object.
        */
		addMessageMutualBot(state, action) {
			// Add a new message object to the current state.messages array and increment id
			state.messages.push(action.payload);
			state.id = state.id + 1;

			if (action.payload.cur_user && action.payload.cur_user !== state.curUser) {
				state.curUser = action.payload.cur_user
			};
		},
		updateMessageMutualBot(state, action) {
			// Destructure the message object for implicit access to necessary values
			const { id, newContent } = action.payload;

			// Find the message in our current state which corresponds to the message we want to update
			const existingMessage = state.messages.find(message => message.id === id);

			// The following conditionals reassign the contents of the message object that we wish to update
			// and allow us to stream new text to the corresponding component.
			if (newContent) existingMessage.content = newContent;

			if (action.payload.cur_user && action.payload.cur_user !== state.curUser) {
				state.curUser = action.payload.cur_user
			};
		},
		clearMessagesMutualBot(state) {
			// Clears all messages from the current state and sets id back to 1, effectively clearing the history from the chat component upon navigation.
			state.messages = [];
			state.id = 1;

		},
	}
})

export const { addMessageMutualBot, updateMessageMutualBot, clearMessagesMutualBot } = mutualBotChatSlice.actions

export default mutualBotChatSlice.reducer;