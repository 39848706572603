import { useNavigate } from 'react-router-dom';

import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as Green_logo } from '../../assets/icons/green_logo.svg';

import { setClickedHome, setClickedSignUp } from "../../model/reducers/menu"
import { clearMessagesMutualBot } from '../../model/reducers/mutualBot';

import { clearSession } from '../../model/reducers/session';
import { clearApiKey } from '../../model/reducers/apiKey';

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const session = useSelector(state => state.session);
  const apiKey = useSelector(state => state.apiKey);

  const animationRef = useRef();
  const [isInViewport, setIsInViewport] = useState(false);

  const [isHoveringLogIn, setIsHoveringLogIn] = useState(false);

  const [isHoveringApiKey, setIsHoveringApiKey] = useState(false);
  const [copiedApiKey, setCopiedApiKey] = useState(false);

  const [clearedSession, setClearedSession] = useState(false);

  /*
  The Intersection Observer below is created to observe the animationRef. When animationRef comes into view (entry.isIntersecting becomes true), the state isInViewport is set to true, which triggers the 'fade-down' animation.

  The animation is triggered when the parent element with animationRef is scrolled into view.
  */
  useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            setIsInViewport(entry.isIntersecting);
          });
        }
      );

      if (animationRef.current) observer.observe(animationRef.current);

      return () => {
        if (animationRef.current) observer.unobserve(animationRef.current);
      };
  }, []);

  useEffect(() => {
    if (clearedSession === true) {
      dispatch(clearApiKey());
      setClearedSession(false);

      return;
    };
  }, [clearedSession]);

  return (
    <nav
    ref={animationRef}
    className='top-0 bg-white h-[8vh]'>
      <ul className="flex h-[8vh] justify-between space-x-[1vw] items-center w-full text-base pr-[2vw] bg-white top-0 fixed z-50">
        <div
        onClick={() => {
          // Clears the messages from main chatbot and updates current menu state in order to display the SplashPage component
          dispatch(clearMessagesMutualBot());
          dispatch(setClickedHome());
          dispatch(setClickedSignUp(false));
          navigate('/');
        }}
        className={`${isInViewport && 'fade-down'} flex justify-center items-center cursor-pointer`}>
          <Green_logo className="w-[3vw] h-[4vh] ml-[4vw] mr-[0.5vw]" />

          <div className=" font-bold text-[1.3rem]">
            MUTUAL
          </div>
          {/* <Mutual_logo className="w-[5rem] h-[1rem]"/> */}
        </div>

        <div className={`${isInViewport && 'fade-down'} flex justify-end space-x-[2vw] w-fit items-center`}>
          <div
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/login');
          }}
          onMouseEnter={() => {
            if (session.token) setIsHoveringLogIn(true);
          }}
          onMouseLeave={() => {
            if (session.token) setIsHoveringLogIn(false);
          }}
          className={`cursor-pointer flex justify-center items-center w-fit h-[19px] text-center max-sm:text-[0.7rem]
          align-middle font-[600] text-[1rem]`}>
            {
              (session.token && !isHoveringLogIn) ?
              'Logged in' :
              !session.token && 'Log In'
            }

            <div
            onClick={e => {
              e.stopPropagation();

              dispatch(clearSession());
              setClearedSession(true);
            }}
            className={`${!session.token ? 'hidden' : !isHoveringLogIn && 'hidden'} cursor-pointer flex justify-center items-center w-fit h-[19px] text-center max-sm:text-[0.7rem] align-middle font-[600] text-[1rem]`}>
                Log Out
            </div>
          </div>

          <div
          // onClick={() => window.open('https://use.mutual.info/dashboard?redirected_uri=https://mutual.info', '_blank')}
          onClick={()=> window.open('https://use.mutual.info', '_blank')}
          className='cursor-pointer flex justify-center items-center w-fit h-[19px] text-center max-sm:text-[0.7rem] align-middle font-[600] text-[1rem]'>
            Playground (Alpha)
          </div>

          <div
          onClick={() => navigate('/pricing')}
          className='cursor-pointer flex justify-center items-center w-fit h-[19px] text-center max-sm:text-[0.7rem] align-middle font-[600] text-[1rem]'>
            Pricing
          </div>

          <div
          onClick={() => navigate('/enterprise')}
          className='cursor-pointer flex justify-center items-center w-fit h-[19px] text-center max-sm:text-[0.7rem] align-middle font-[600] text-[1rem]'>
            Enterprise
          </div>

          {/* <div
          onClick={() => window.open('https://discord.gg/vQNsUbSQxg', '_blank')}
          className='cursor-pointer flex justify-center items-center w-fit h-[19px] text-center max-sm:text-[0.7rem] align-middle font-[600] text-[1rem] pr-4'>
            Discord
          </div> */}

          <div
          onClick={() => {
            if (apiKey.apiKey) {
              navigator.clipboard.writeText(apiKey.apiKey);
              setCopiedApiKey(true);

              return;
            };

            navigate('/');
            window.scrollTo(0, 0);
            dispatch(setClickedSignUp(true));
          }}
          onMouseEnter={() => apiKey.apiKey && setIsHoveringApiKey(true)}
          onMouseLeave={() => {
            if (apiKey.apiKey) {
              setIsHoveringApiKey(false);
              setCopiedApiKey(false);
            };
          }}
          className={`
            ${(isHoveringApiKey || copiedApiKey) ? 'w-[45vw] px-4 bg-white shadow-lg' : 'bg-black text-white'}
            flex justify-center w-[160px] h-[52px] text-center items-center rounded-md cursor-pointer font-[600] text-[1rem] max-sm:text-[0.7rem] max-sm:w-[8em]
          `}>
            <span>
              {
                (apiKey.apiKey && copiedApiKey) ? 'Copied!'
                : (apiKey.apiKey && !isHoveringApiKey) ? 'View API Key'
                : (apiKey.apiKey && isHoveringApiKey) ? <span>{ apiKey.apiKey }</span>
                : 'Register Now'
              }
            </span>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default NavBar;
