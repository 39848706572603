import { GoogleAuthProvider, GithubAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../config/firebase.js";

import { setToken, setFirebaseUid, setUser } from "../model/reducers/session.js";
import { setApiKey } from "../model/reducers/apiKey.js";
import { setError } from "../model/reducers/menu.js";

const fetchSignUpOrLogIn = async (isProvider, uri, dispatch, navigate, provider, isSignUp, email, password) => {
    const providers = {
        'google': new GoogleAuthProvider(),
        'github': new GithubAuthProvider(),
        'facebook': new FacebookAuthProvider()
    };

    let userCredential;
    try {
        isProvider ? userCredential = await signInWithPopup(auth, providers[provider]) : (!provider && isSignUp) ? userCredential = await createUserWithEmailAndPassword(auth, email, password) : (!provider && !isSignUp) ? userCredential = await signInWithEmailAndPassword(auth, email, password) : userCredential = null;
        const token = await userCredential.user.getIdToken();
                        
        const req = await fetch(`${uri}/api/signup-with-provider`, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ "access_token": token })
        });
                        
        const res = await req.json()

        dispatch(setToken(token));
        dispatch(setFirebaseUid(res.firebase_uid));
        dispatch(setApiKey(res.api_key));
        dispatch(setUser(userCredential.user));

        navigate('/');
    } catch (error) {
        dispatch(setError(error.message));
    };
};

export default fetchSignUpOrLogIn;