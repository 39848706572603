import { useState, useEffect } from 'react';

import '../index.css';

const HeaderTextAnimation = () => {
    const [index, setIndex] = useState(1);
    const [currWord, setCurrWord] = useState('Assistant');

    useEffect(() => {
        const words = ['Assistant', 'Tutor', 'Salesman'];

        const wordInterval = setInterval(() => {
            setCurrWord(words[index]);

            if (index < 2) setIndex(index + 1);
            else (setIndex(0));
        }, [3000]);

        return () => clearInterval(wordInterval);
    }, [index]);

    return (
        <div className='h-fit w-fit relative'>
            <span id='header-text-animation' key={index}>
                { currWord }
            </span>

            {/* The below span allows the parent div to maintain correct height/width */}
            <span className='invisible'>{ currWord }</span>
        </div>
    );
};

export default HeaderTextAnimation;