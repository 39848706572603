import HeaderComponent from "./HeaderComponent";
import MutualBot from "./MutualBot";
import ArticlesContainer from "./ArticlesContainer";

// Serves as the main container for all of our splashpage related components
const SplashPage = () => {
    return (
        <div className='font-Inter'>
            <HeaderComponent />

            <MutualBot />

            <ArticlesContainer />
        </div>
    );
};

export default SplashPage;