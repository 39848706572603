import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { setIsExpired } from './model/reducers/session';

import fetchUserData from './controller/fetchUserData'

import NavBar from './view/components/NavBar';
import AuthError from './view/components/AuthError.js';
import LogInContainer from './view/pages/LogInContainer';
import PricingContainer from './view/pages/PricingContainer';
import BaseContainer from './view/pages/BaseContainer';
import EnterpriseContainer from './view/pages/EnterpriseContainer';
import ArticlePage from './view/pages/ArticlePage';
import Footer from './view/components/Footer';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';

const App = () => {
  const dispatch = useDispatch();

  const session = useSelector(state => state.session);
  const apiKey = useSelector(state => state.apiKey);
  useEffect(() => {
    if (!session.token) {
      dispatch(setIsExpired(true));

      return;
    };

    const token = session.token;
    const decodedToken = jwtDecode(token);

    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      dispatch(setIsExpired(true));
    } else {
      dispatch(setIsExpired(false));
    };
  }, []);

  useEffect(() => {
    if (session.tokenExpired === true) {
      const currentUser = auth.currentUser;

      if (currentUser) {
        currentUser.getIdToken(true).then(refreshedToken => {
          fetchUserData(refreshedToken, dispatch);
          dispatch(setIsExpired(false));
        }).catch(err => {
          console.error("Error refreshing token: ", err);
        });
      };
    };
  }, [session.tokenExpired]);

  useEffect(() => {
    if (session.token && !apiKey.apiKey) {
      onAuthStateChanged(auth, (currUser) => {
          if (currUser) {
            currUser.getIdToken().then(token => {
                fetchUserData(token, dispatch);
            });
          } else {
            dispatch(setIsExpired(true));
            
            return;
          };
      });
    };
  }, []);

  return (
    <div className="App h-full w-full">
      <Router>
        <NavBar/>
        
        <AuthError />
        
        <Routes>
          <Route path='/' exact element={<BaseContainer />} />

          <Route path='/login' exact element={<LogInContainer />} />

          <Route path='/pricing' exact element={<PricingContainer />} />

          <Route path='/enterprise' exact element={<EnterpriseContainer />} />

          <Route path='/:article' element={<ArticlePage />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
};

export default App;