const HeaderBot = () => {
    return (
        <svg width="36.06vw" height="78.51vh" viewBox="0 0 564 570" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M525.963 276.04C495.21 243.686 443.568 242.369 411.214 273.135L349.708 331.594C328.729 323.822 304.147 327.979 286.911 344.363L86.6045 534.754H262.059L301.487 497.275C307.065 509.475 314.734 520.992 324.494 531.256C371.889 581.116 450.72 583.117 500.581 535.723C541.649 496.681 550.209 436.311 525.472 388.375C555.45 357.389 555.941 307.568 525.976 276.027L525.963 276.04Z" fill="#FFF6E6"/>
            <path d="M376.162 120.52C376.162 78.5091 342.104 44.464 300.106 44.464C258.108 44.464 224.05 78.522 224.05 120.52C224.05 157.715 250.762 188.675 286.046 195.272L299.447 224.075L310.744 195.84C347.72 190.663 376.162 158.916 376.162 120.52Z" fill="#FFF6E6"/>
            <path d="M201.121 125.219C181.756 92.801 139.77 82.2144 107.339 101.58C74.9209 120.946 64.3342 162.931 83.7 195.362C100.858 224.075 135.742 235.656 166.018 224.476L189.644 240.523L185.345 213.514C211.501 192.47 218.822 154.849 201.121 125.219Z" fill="#FFF6E6"/>
            <path d="M399.349 125.219C418.715 92.801 460.7 82.2144 493.131 101.58C525.549 120.946 536.136 162.931 516.77 195.362C499.612 224.075 464.728 235.656 434.452 224.476L410.826 240.523L415.125 213.514C388.969 192.47 381.648 154.849 399.349 125.219Z" fill="#FFF6E6"/>
            <path d="M338.206 105.221C338.206 84.6157 321.5 67.8966 300.882 67.8966C280.264 67.8966 263.558 84.6028 263.558 105.221C263.558 120.636 272.892 133.857 286.229 139.55V160.788C286.229 168.689 292.697 175.157 300.598 175.157C308.499 175.157 314.967 168.689 314.967 160.788V139.782C328.588 134.218 338.193 120.843 338.193 105.221H338.206Z" fill="#DCFE56"/>
            <path d="M301.851 67.9223V175.106C309.184 174.473 314.981 168.276 314.981 160.788V139.782C328.601 134.218 338.207 120.843 338.207 105.221C338.207 84.9384 322.017 68.4258 301.863 67.9094L301.851 67.9223Z" fill="#C6E54D"/>
            <path d="M286.784 154.707V167.979C286.784 175.88 293.253 182.348 301.154 182.348C309.055 182.348 315.523 175.88 315.523 167.979V154.707H286.771H286.784Z" fill="#091D21"/>
            <path d="M317.473 172.678H285.404C282.821 172.678 280.704 170.561 280.704 167.979C280.704 165.397 282.821 163.28 285.404 163.28H317.473C320.055 163.28 322.173 165.397 322.173 167.979C322.173 170.561 320.055 172.678 317.473 172.678Z" fill="#D2D4FF"/>
            <path d="M273.55 94.3373C272.44 96.6612 271.717 99.1659 271.407 101.735" stroke="white" strokeWidth="3.43" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M294.634 77.9668C288.553 79.6193 282.743 83.2214 278.276 87.611" stroke="white" strokeWidth="3.43" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M316.362 164.106H284.292C281.71 164.106 279.593 161.988 279.593 159.406C279.593 156.824 281.71 154.707 284.292 154.707H316.362C318.944 154.707 321.061 156.824 321.061 159.406C321.061 161.988 318.944 164.106 316.362 164.106Z" fill="#9297FF"/>
            <path d="M315.535 155.262H283.465C280.883 155.262 278.766 153.145 278.766 150.563C278.766 147.981 280.883 145.863 283.465 145.863H315.535C318.117 145.863 320.234 147.981 320.234 150.563C320.234 153.145 318.117 155.262 315.535 155.262Z" fill="#D2D4FF"/>
            <path d="M292.812 145.192V112.49L300.456 133.727L308.099 113.768V145.618" stroke="#091D21" strokeWidth="0.86" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M164.443 127.504H120.999C115.293 127.504 110.658 132.139 110.658 137.846V181.29C110.658 186.996 115.293 191.631 120.999 191.631H164.443C170.149 191.631 174.784 186.996 174.784 181.29V137.846C174.784 132.139 170.149 127.504 164.443 127.504Z" fill="#9297FF"/>
            <path d="M157.588 137.613H127.842C123.943 137.613 120.767 140.789 120.767 144.688V174.434C120.767 178.333 123.943 181.509 127.842 181.509H157.588C161.487 181.509 164.663 178.333 164.663 174.434V144.688C164.663 140.789 161.487 137.613 157.588 137.613Z" stroke="white" strokeWidth="1.53" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M132.477 131.623C130.824 131.623 129.481 130.267 129.481 128.628V117.047C129.481 115.394 130.837 114.052 132.477 114.052C134.129 114.052 135.472 115.407 135.472 117.047V128.628C135.472 130.28 134.116 131.623 132.477 131.623Z" fill="#DCFE56"/>
            <path d="M142.818 131.623C141.165 131.623 139.822 130.267 139.822 128.628V117.047C139.822 115.394 141.178 114.052 142.818 114.052C144.47 114.052 145.813 115.407 145.813 117.047V128.628C145.813 130.28 144.457 131.623 142.818 131.623Z" fill="#DCFE56"/>
            <path d="M152.952 131.623C151.3 131.623 149.957 130.267 149.957 128.628V117.047C149.957 115.394 151.313 114.052 152.952 114.052C154.605 114.052 155.948 115.407 155.948 117.047V128.628C155.948 130.28 154.592 131.623 152.952 131.623Z" fill="#DCFE56"/>
            <path d="M132.477 205.768C130.824 205.768 129.481 204.412 129.481 202.773V191.192C129.481 189.539 130.837 188.197 132.477 188.197C134.129 188.197 135.472 189.552 135.472 191.192V202.773C135.472 204.425 134.116 205.768 132.477 205.768Z" fill="#DCFE56"/>
            <path d="M142.818 205.768C141.165 205.768 139.822 204.412 139.822 202.773V191.192C139.822 189.539 141.178 188.197 142.818 188.197C144.47 188.197 145.813 189.552 145.813 191.192V202.773C145.813 204.425 144.457 205.768 142.818 205.768Z" fill="#DCFE56"/>
            <path d="M152.952 205.768C151.3 205.768 149.957 204.412 149.957 202.773V191.192C149.957 189.539 151.313 188.197 152.952 188.197C154.605 188.197 155.948 189.552 155.948 191.192V202.773C155.948 204.425 154.592 205.768 152.952 205.768Z" fill="#DCFE56"/>
            <path d="M96.998 169.799V169.812C96.998 171.466 98.3391 172.807 99.9933 172.807H111.587C113.241 172.807 114.582 171.466 114.582 169.812V169.799C114.582 168.145 113.241 166.804 111.587 166.804H99.9933C98.3391 166.804 96.998 168.145 96.998 169.799Z" fill="#DCFE56"/>
            <path d="M114.582 159.458C114.582 161.11 113.227 162.453 111.587 162.453H100.006C98.3539 162.453 97.0112 161.098 97.0112 159.458C97.0112 157.805 98.3668 156.463 100.006 156.463H111.587C113.24 156.463 114.582 157.818 114.582 159.458Z" fill="#DCFE56"/>
            <path d="M114.582 149.323C114.582 150.976 113.227 152.318 111.587 152.318H100.006C98.3539 152.318 97.0112 150.963 97.0112 149.323C97.0112 147.671 98.3668 146.328 100.006 146.328H111.587C113.24 146.328 114.582 147.684 114.582 149.323Z" fill="#DCFE56"/>
            <path d="M170.214 169.786V169.799C170.214 171.454 171.555 172.795 173.209 172.795H184.803C186.457 172.795 187.798 171.454 187.798 169.799V169.786C187.798 168.132 186.457 166.791 184.803 166.791H173.209C171.555 166.791 170.214 168.132 170.214 169.786Z" fill="#DCFE56"/>
            <path d="M187.811 159.458C187.811 161.11 186.455 162.453 184.816 162.453H173.235C171.582 162.453 170.24 161.098 170.24 159.458C170.24 157.805 171.595 156.463 173.235 156.463H184.816C186.468 156.463 187.811 157.818 187.811 159.458Z" fill="#DCFE56"/>
            <path d="M187.811 149.323C187.811 150.976 186.455 152.318 184.816 152.318H173.235C171.582 152.318 170.24 150.963 170.24 149.323C170.24 147.671 171.595 146.328 173.235 146.328H184.816C186.468 146.328 187.811 147.684 187.811 149.323Z" fill="#DCFE56"/>
            <path d="M150.629 149.517H134.8V171.284H150.629V149.517Z" fill="#091D21"/>
            <path d="M467.071 189.616C485.039 182.316 493.687 161.832 486.388 143.864C479.088 125.896 458.604 117.247 440.636 124.547C422.668 131.847 414.019 152.331 421.319 170.299C428.619 188.267 449.102 196.916 467.071 189.616Z" fill="#C3BDFF"/>
            <mask id="mask0_128_35" maskUnits="userSpaceOnUse" x="418" y="121" width="71" height="72">
            <path d="M467.072 189.616C485.04 182.316 493.688 161.833 486.389 143.864C479.089 125.896 458.605 117.248 440.637 124.547C422.669 131.847 414.02 152.331 421.32 170.299C428.62 188.267 449.103 196.916 467.072 189.616Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_128_35)">
            <path d="M419.581 161.666H489.053" stroke="#091D21" strokeWidth="0.86" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M454.323 122.973L454.427 191.683" stroke="#091D21" strokeWidth="0.86" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M458.609 122.534C458.609 122.534 480.815 152.654 458.39 191.347" stroke="#091D21" strokeWidth="0.86" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M464.406 122.973C464.406 122.973 497.082 152.215 463.438 190.908" stroke="#091D21" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M449.071 122.857C449.071 122.857 426.865 152.977 449.291 191.67" stroke="#091D21" strokeWidth="0.86" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M443.273 123.309C443.273 123.309 410.597 152.551 444.242 191.244" stroke="#091D21" strokeWidth="0.77" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <path d="M502.685 147.154H473.662C468.95 147.154 465.09 143.294 465.09 138.582C465.09 133.869 468.95 130.009 473.662 130.009H502.685C507.398 130.009 511.258 133.869 511.258 138.582C511.258 143.294 507.398 147.154 502.685 147.154Z" fill="#279F71"/>
            <path d="M478.503 138.581C478.503 139.911 477.419 140.996 476.089 140.996C474.759 140.996 473.675 139.911 473.675 138.581C473.675 137.252 474.759 136.167 476.089 136.167C477.419 136.167 478.503 137.252 478.503 138.581Z" fill="white"/>
            <path d="M489 140.996C490.334 140.996 491.414 139.915 491.414 138.582C491.414 137.248 490.334 136.167 489 136.167C487.667 136.167 486.586 137.248 486.586 138.582C486.586 139.915 487.667 140.996 489 140.996Z" fill="white"/>
            <path d="M503.951 138.582C503.951 139.911 502.866 140.996 501.536 140.996C500.207 140.996 499.122 139.911 499.122 138.582C499.122 137.252 500.207 136.167 501.536 136.167C502.866 136.167 503.951 137.252 503.951 138.582Z" fill="white"/>
            <path d="M12.8985 193.916H64.2307C70.544 193.916 75.6695 199.029 75.6695 205.355V228.865C75.6695 235.178 70.5569 240.304 64.2307 240.304H62.6815L65.0699 252.233L46.9048 240.304H12.9114C6.59814 240.304 1.47266 235.191 1.47266 228.865V205.355C1.47266 199.042 6.58523 193.916 12.9114 193.916H12.8985Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M142.21 14.9504H213.386C222.139 14.9504 229.24 22.0512 229.24 30.8045V63.4036C229.24 72.1569 222.139 79.2577 213.386 79.2577H211.23L214.535 95.7961L189.346 79.2577H142.21C133.457 79.2577 126.356 72.1569 126.356 63.4036V30.8045C126.356 22.0512 133.457 14.9504 142.21 14.9504Z" stroke="#091D21" strokeWidth="1.42" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M460.623 57.5551H411.989C406.012 57.5551 401.157 62.4094 401.157 68.387V90.6577C401.157 96.6352 406.012 101.49 411.989 101.49H413.461L411.202 112.786L428.424 101.49H460.636C466.614 101.49 471.468 96.6352 471.468 90.6577V68.387C471.468 62.4094 466.614 57.5551 460.636 57.5551H460.623Z" stroke="#091D21" strokeWidth="0.97" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M125.245 261.18C125.245 261.18 156.863 275.136 163.383 302.106" stroke="#091D21" strokeWidth="3.36" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M112.696 280.714C126.308 280.714 137.343 269.679 137.343 256.067C137.343 242.456 126.308 231.421 112.696 231.421C99.0848 231.421 88.0503 242.456 88.0503 256.067C88.0503 269.679 99.0848 280.714 112.696 280.714Z" fill="#D2D4FF"/>
            <path d="M457.098 263.233C457.098 263.233 425.48 277.189 418.96 304.159" stroke="#091D21" strokeWidth="3.36" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M469.659 282.766C483.271 282.766 494.305 271.732 494.305 258.12C494.305 244.508 483.271 233.474 469.659 233.474C456.047 233.474 445.013 244.508 445.013 258.12C445.013 271.732 456.047 282.766 469.659 282.766Z" fill="#D2D4FF"/>
            <path d="M157.844 326.559H122.495C91.6711 326.559 66.6831 351.547 66.6831 382.371C66.6831 413.195 91.6711 438.183 122.495 438.183H157.844C188.669 438.183 213.657 413.195 213.657 382.371C213.657 351.547 188.669 326.559 157.844 326.559Z" fill="#D2D4FF"/>
            <path d="M157.844 326.559H122.495C115.756 326.559 109.288 327.773 103.284 329.98C102.936 334.551 103 339.173 103.375 343.665C104.278 354.51 106.705 367.111 112.838 376.316C115.898 380.912 116.892 385.586 116.195 391.06C115.433 396.921 114.491 402.757 114.258 408.683C113.871 418.495 115.007 428.462 117.705 437.964C119.293 438.106 120.894 438.171 122.508 438.171H157.857C188.558 438.171 213.669 413.06 213.669 382.358C213.669 351.657 188.558 326.546 157.857 326.546L157.844 326.559Z" fill="#091D21"/>
            <path d="M94.9058 334.473C94.9058 334.473 111.651 384.075 94.9058 430.579" stroke="#091D21" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M476.568 441.902H441.219C410.414 441.902 385.406 416.894 385.406 386.089C385.406 355.285 410.414 330.277 441.219 330.277H476.568C507.372 330.277 532.38 355.285 532.38 386.089C532.38 416.894 507.372 441.902 476.568 441.902Z" fill="#D2D4FF"/>
            <path d="M441.205 330.277H476.554C483.293 330.277 489.762 331.491 495.765 333.698C496.114 338.269 496.049 342.891 495.675 347.384C494.771 358.228 492.344 370.829 486.211 380.034C483.151 384.631 482.157 389.304 482.854 394.778C483.616 400.64 484.559 406.475 484.791 412.401C485.178 422.213 484.042 432.18 481.344 441.682C479.756 441.824 478.155 441.889 476.541 441.889H441.192C410.491 441.889 385.38 416.778 385.38 386.076C385.38 355.375 410.491 330.264 441.192 330.264L441.205 330.277Z" fill="#091D21"/>
            <path d="M504.144 338.204C504.144 338.204 487.399 387.806 504.144 434.31" stroke="#091D21" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M338.308 248.424H247.134C180.352 248.424 126.214 302.562 126.214 369.344V369.357C126.214 436.14 180.352 490.277 247.134 490.277H338.308C405.09 490.277 459.228 436.14 459.228 369.357V369.344C459.228 302.562 405.09 248.424 338.308 248.424Z" fill="#9297FF"/>
            <path d="M166.146 299.434C187.487 312.951 214.78 314.552 239.13 310.46C262.885 306.47 287.428 296.891 304.767 279.694C305.077 279.384 305.593 279.319 305.967 279.539C326.456 291.765 350.044 301.087 374.329 298.879C391.538 297.317 406.334 289.932 418.909 279.41C397.477 260.173 369.203 248.424 338.308 248.424H247.147C210.546 248.424 177.598 264.911 155.379 290.823C158.619 294.05 162.209 296.942 166.146 299.434Z" fill="#D2D4FF"/>
            <path d="M373.011 318.361H217.375C182.957 318.361 155.056 346.262 155.056 380.68C155.056 415.098 182.957 442.999 217.375 442.999H373.011C407.429 442.999 435.33 415.098 435.33 380.68C435.33 346.262 407.429 318.361 373.011 318.361Z" fill="#091D21"/>
            <path d="M260.147 388.426C252.13 388.426 245.571 381.868 245.571 373.85C245.571 365.833 252.13 359.274 260.147 359.274C268.165 359.274 274.723 365.833 274.723 373.85C274.723 381.868 268.165 388.426 260.147 388.426Z" fill="#DCFE56"/>
            <path d="M341.691 359.287L315.65 377.259L344.789 389.046" stroke="#DCFE56" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M283.411 400.201V404.229C283.411 409.884 287.994 414.454 293.636 414.454C299.291 414.454 303.861 409.871 303.861 404.229V400.201" stroke="#DCFE56" strokeWidth="2.88" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M213.179 421.684C223.711 421.684 232.248 413.146 232.248 402.615C232.248 392.083 223.711 383.546 213.179 383.546C202.648 383.546 194.11 392.083 194.11 402.615C194.11 413.146 202.648 421.684 213.179 421.684Z" fill="#CCC5B8"/>
            <path d="M394.405 406.866C396.84 396.62 390.509 386.34 380.263 383.904C370.017 381.469 359.736 387.801 357.301 398.047C354.866 408.293 361.198 418.573 371.444 421.009C381.69 423.444 391.97 417.112 394.405 406.866Z" fill="#CCC5B8"/>
            <path d="M240.033 550.092H109.443C92.8529 550.092 79.271 536.51 79.271 519.92C79.271 503.33 92.8529 489.748 109.443 489.748H240.033C256.636 489.748 270.205 503.33 270.205 519.92C270.205 536.51 256.623 550.092 240.033 550.092Z" fill="#091D21"/>
            <path d="M227.174 537.22H96.584C79.9811 537.22 66.4121 523.638 66.4121 507.048C66.4121 490.458 79.994 476.876 96.584 476.876H227.174C243.777 476.876 257.346 490.458 257.346 507.048C257.346 523.638 243.764 537.22 227.174 537.22Z" fill="#279F71"/>
            <path d="M170.291 483.796L216.781 447.195L206.892 483.796H170.291Z" fill="#279F71"/>
            <path d="M119.006 521.54C116.412 521.515 114.404 519.591 114.43 516.917C114.456 514.161 116.5 512.397 119.093 512.422C121.727 512.448 123.696 514.25 123.669 517.005C123.644 519.68 121.64 521.566 119.006 521.54Z" fill="white"/>
            <path d="M132.261 521.303L132.473 499.298L123.76 499.214L123.821 492.852L149.23 493.096L149.169 499.458L140.497 499.375L140.285 521.38L132.261 521.303Z" fill="white"/>
            <path d="M177.871 521.741L168.632 521.652L162.84 512.802L156.961 521.54L147.802 521.452L158.274 507.206L148.52 493.089L157.557 493.176L163.152 501.416L168.823 493.284L177.495 493.367L167.476 506.97L177.871 521.741Z" fill="white"/>
            <path d="M185.41 521.813L185.621 499.808L176.909 499.725L176.97 493.362L202.378 493.606L202.317 499.969L193.645 499.885L193.434 521.89L185.41 521.813Z" fill="white"/>
            <path d="M538.782 309.724H483.073C476.218 309.724 470.666 315.275 470.666 322.131V347.642C470.666 354.497 476.218 360.049 483.073 360.049H484.751L482.156 372.985L501.884 360.049H538.782C545.637 360.049 551.189 354.497 551.189 347.642V322.131C551.189 315.275 545.637 309.724 538.782 309.724Z" fill="#091D21"/>
            <path d="M513.065 290.836H449.042C441.166 290.836 434.775 297.226 434.775 305.102V334.422C434.775 342.297 441.166 348.688 449.042 348.688H450.978L448.009 363.561L470.68 348.688H513.078C520.953 348.688 527.344 342.297 527.344 334.422V305.102C527.344 297.226 520.953 290.836 513.078 290.836H513.065Z" fill="#DCFE56"/>
            <path d="M476.589 305.82L476.51 314.08L458.402 320.299L476.387 326.865L476.308 335.124L448.517 324.561L448.6 315.849L476.589 305.82Z" fill="#091D21"/>
            <path d="M488.68 304.942L488.759 313.202L506.867 319.421L488.882 325.987L488.961 334.246L516.752 323.683L516.668 314.971L488.68 304.942Z" fill="#091D21"/>
            <path d="M41.018 341.083C38.126 341.083 35.7505 338.721 35.7505 335.816V280.662C35.7505 277.77 38.1131 275.395 41.018 275.395C43.9099 275.395 46.2855 277.757 46.2855 280.662V335.816C46.2855 338.708 43.9229 341.083 41.018 341.083Z" fill="#BCE1D3"/>
            <path d="M8.18652 308.239C8.18652 305.347 10.5492 302.971 13.454 302.971H68.6078C71.4998 302.971 73.8753 305.334 73.8753 308.239C73.8753 311.131 71.5127 313.506 68.6078 313.506H13.454C10.5621 313.506 8.18652 311.144 8.18652 308.239Z" fill="#BCE1D3"/>
            <path d="M17.8042 285.013C19.857 282.96 23.2008 282.96 25.2407 285.013L64.2434 324.016C66.2962 326.068 66.2962 329.412 64.2434 331.452C62.1906 333.492 58.8468 333.492 56.8069 331.452L17.8042 292.449C15.7515 290.397 15.7515 287.053 17.8042 285.013Z" fill="#BCE1D3"/>
            <path d="M64.2434 285.013C66.2962 287.066 66.2962 290.41 64.2434 292.449L25.2407 331.452C23.1879 333.492 19.8441 333.492 17.8042 331.452C15.7515 329.399 15.7515 326.055 17.8042 324.016L56.8069 285.013C58.8597 282.96 62.2036 282.96 64.2434 285.013Z" fill="#BCE1D3"/>
            <path d="M49.649 327.634C59.6949 323.473 64.4655 311.956 60.3043 301.91C56.1432 291.864 44.626 287.093 34.58 291.255C24.534 295.416 19.7634 306.933 23.9246 316.979C28.0858 327.025 39.603 331.795 49.649 327.634Z" fill="#BCE1D3"/>
            <path d="M42.1153 318.116C46.9069 318.116 50.7912 314.231 50.7912 309.44C50.7912 304.648 46.9069 300.764 42.1153 300.764C37.3238 300.764 33.4395 304.648 33.4395 309.44C33.4395 314.231 37.3238 318.116 42.1153 318.116Z" fill="white"/>
            <path d="M42.4638 75.8365C40.4111 75.8365 38.7456 74.1582 38.7456 72.1183V33.1156C38.7456 31.0628 40.424 29.3973 42.4638 29.3973C44.5166 29.3973 46.1821 31.0757 46.1821 33.1156V72.1183C46.1821 74.1711 44.5037 75.8365 42.4638 75.8365Z" fill="#BCE1D3"/>
            <path d="M19.2373 52.6234C19.2373 50.5706 20.9157 48.9052 22.9555 48.9052H61.9583C64.011 48.9052 65.6765 50.5835 65.6765 52.6234C65.6765 54.6762 63.9981 56.3416 61.9583 56.3416H22.9555C20.9028 56.3416 19.2373 54.6633 19.2373 52.6234Z" fill="#BCE1D3"/>
            <path d="M26.042 36.2012C27.488 34.7553 29.8506 34.7553 31.3095 36.2012L58.8864 63.7781C60.3324 65.2241 60.3324 67.5868 58.8864 69.0456C57.4404 70.4916 55.0778 70.4916 53.6189 69.0456L26.042 41.4687C24.596 40.0228 24.596 37.6601 26.042 36.2012Z" fill="#BCE1D3"/>
            <path d="M58.8864 36.2012C60.3324 37.6472 60.3324 40.0098 58.8864 41.4687L31.3095 69.0456C29.8635 70.4916 27.5009 70.4916 26.042 69.0456C24.596 67.5997 24.596 65.237 26.042 63.7781L53.6189 36.2012C55.0649 34.7553 57.4275 34.7553 58.8864 36.2012Z" fill="#BCE1D3"/>
            <path d="M43.2381 67.393C50.9317 67.393 57.1685 61.1561 57.1685 53.4625C57.1685 45.7689 50.9317 39.532 43.2381 39.532C35.5445 39.532 29.3076 45.7689 29.3076 53.4625C29.3076 61.1561 35.5445 67.393 43.2381 67.393Z" fill="#BCE1D3"/>
            <path d="M43.238 59.5951C46.6249 59.5951 49.3705 56.8495 49.3705 53.4626C49.3705 50.0757 46.6249 47.3301 43.238 47.3301C39.8511 47.3301 37.1055 50.0757 37.1055 53.4626C37.1055 56.8495 39.8511 59.5951 43.238 59.5951Z" fill="white"/>
            <path d="M223.677 238.186C228.048 238.186 231.591 234.643 231.591 230.272C231.591 225.901 228.048 222.358 223.677 222.358C219.306 222.358 215.763 225.901 215.763 230.272C215.763 234.643 219.306 238.186 223.677 238.186Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M511.076 485.772C511.076 490.148 507.526 493.686 503.162 493.686C498.798 493.686 495.248 490.135 495.248 485.772C495.248 481.408 498.798 477.858 503.162 477.858C507.526 477.858 511.076 481.408 511.076 485.772Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M385.431 51.4484C385.431 55.8251 381.88 59.3626 377.517 59.3626C373.153 59.3626 369.603 55.8122 369.603 51.4484C369.603 47.0846 373.153 43.5342 377.517 43.5342C381.88 43.5342 385.431 47.0846 385.431 51.4484Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M41.134 414.544C41.134 418.921 37.5836 422.458 33.2198 422.458C28.8561 422.458 25.3057 418.908 25.3057 414.544C25.3057 410.181 28.8561 406.63 33.2198 406.63C37.5836 406.63 41.134 410.181 41.134 414.544Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M57.9431 158.296C57.9431 162.673 54.3927 166.21 50.0289 166.21C45.6651 166.21 42.1147 162.66 42.1147 158.296C42.1147 153.932 45.6651 150.382 50.0289 150.382C54.3927 150.382 57.9431 153.932 57.9431 158.296Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M237.025 194.91C237.025 197.092 235.256 198.874 233.061 198.874C230.866 198.874 229.098 197.105 229.098 194.91C229.098 192.716 230.866 190.947 233.061 190.947C235.256 190.947 237.025 192.716 237.025 194.91Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M550.648 429.378C550.648 431.56 548.879 433.342 546.684 433.342C544.489 433.342 542.721 431.573 542.721 429.378C542.721 427.184 544.489 425.415 546.684 425.415C548.879 425.415 550.648 427.184 550.648 429.378Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M354.757 25.7307C354.757 27.9126 352.988 29.6942 350.794 29.6942C348.599 29.6942 346.83 27.9255 346.83 25.7307C346.83 23.5359 348.599 21.7672 350.794 21.7672C352.988 21.7672 354.757 23.5359 354.757 25.7307Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M407.186 244.383C409.375 244.383 411.15 242.609 411.15 240.42C411.15 238.231 409.375 236.456 407.186 236.456C404.997 236.456 403.223 238.231 403.223 240.42C403.223 242.609 404.997 244.383 407.186 244.383Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M530.856 246.359C530.856 248.541 529.087 250.322 526.892 250.322C524.697 250.322 522.929 248.554 522.929 246.359C522.929 244.164 524.697 242.395 526.892 242.395C529.087 242.395 530.856 244.164 530.856 246.359Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M107.416 51.4485C107.416 53.6304 105.648 55.4121 103.453 55.4121C101.258 55.4121 99.4893 53.6433 99.4893 51.4485C99.4893 49.2537 101.258 47.485 103.453 47.485C105.648 47.485 107.416 49.2537 107.416 51.4485Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M529.874 88.0498C529.874 90.2317 528.106 92.0134 525.911 92.0134C523.716 92.0134 521.947 90.2446 521.947 88.0498C521.947 85.855 523.716 84.0863 525.911 84.0863C528.106 84.0863 529.874 85.855 529.874 88.0498Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M40.14 115.756C40.14 117.938 38.3712 119.719 36.1764 119.719C33.9816 119.719 32.2129 117.951 32.2129 115.756C32.2129 113.561 33.9816 111.792 36.1764 111.792C38.3712 111.792 40.14 113.561 40.14 115.756Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M62.8885 439.281C62.8885 441.463 61.1197 443.244 58.925 443.244C56.7302 443.244 54.9614 441.476 54.9614 439.281C54.9614 437.086 56.7302 435.317 58.925 435.317C61.1197 435.317 62.8885 437.086 62.8885 439.281Z" stroke="#091D21" strokeWidth="1.02" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M87.6246 81.6204C87.6246 84.6285 85.1845 87.0557 82.1892 87.0557C79.194 87.0557 76.7539 84.6156 76.7539 81.6204C76.7539 78.6251 79.194 76.1851 82.1892 76.1851C85.1845 76.1851 87.6246 78.6251 87.6246 81.6204Z" fill="#091D21"/>
            <path d="M23.3174 158.8C23.3174 161.253 21.3292 163.254 18.8633 163.254C16.3974 163.254 14.4092 161.266 14.4092 158.8C14.4092 156.334 16.3974 154.345 18.8633 154.345C21.3292 154.345 23.3174 156.334 23.3174 158.8Z" fill="#091D21"/>
            <path d="M168.264 247.353C169.633 247.353 170.743 246.243 170.743 244.874C170.743 243.505 169.633 242.395 168.264 242.395C166.895 242.395 165.785 243.505 165.785 244.874C165.785 246.243 166.895 247.353 168.264 247.353Z" fill="#091D21"/>
            <path d="M110.876 307.697C112.245 307.697 113.355 306.587 113.355 305.218C113.355 303.849 112.245 302.739 110.876 302.739C109.507 302.739 108.397 303.849 108.397 305.218C108.397 306.587 109.507 307.697 110.876 307.697Z" fill="#091D21"/>
            <path d="M38.1639 486.766C38.1639 488.405 36.8342 489.735 35.1945 489.735C33.5549 489.735 32.2251 488.405 32.2251 486.766C32.2251 485.126 33.5549 483.796 35.1945 483.796C36.8342 483.796 38.1639 485.126 38.1639 486.766Z" fill="#091D21"/>
            <path d="M36.1762 377.93C38.9071 377.93 41.1209 375.716 41.1209 372.985C41.1209 370.254 38.9071 368.041 36.1762 368.041C33.4453 368.041 31.2314 370.254 31.2314 372.985C31.2314 375.716 33.4453 377.93 36.1762 377.93Z" fill="#091D21"/>
            <path d="M349.812 537.22C352.543 537.22 354.757 535.006 354.757 532.275C354.757 529.545 352.543 527.331 349.812 527.331C347.081 527.331 344.867 529.545 344.867 532.275C344.867 535.006 347.081 537.22 349.812 537.22Z" fill="#091D21"/>
            <path d="M462.598 473.416C462.598 476.967 459.719 479.846 456.169 479.846C452.618 479.846 449.739 476.967 449.739 473.416C449.739 469.866 452.618 466.987 456.169 466.987C459.719 466.987 462.598 469.866 462.598 473.416Z" fill="#091D21"/>
            <path d="M551.138 468.962C552.507 468.962 553.617 467.852 553.617 466.483C553.617 465.114 552.507 464.005 551.138 464.005C549.769 464.005 548.659 465.114 548.659 466.483C548.659 467.852 549.769 468.962 551.138 468.962Z" fill="#091D21"/>
            <path d="M538.771 192.922C538.771 194.02 537.88 194.897 536.796 194.897C535.711 194.897 534.82 194.007 534.82 192.922C534.82 191.838 535.711 190.947 536.796 190.947C537.88 190.947 538.771 191.838 538.771 192.922Z" fill="#091D21"/>
            <path d="M517.997 43.5344C517.997 45.174 516.668 46.5038 515.028 46.5038C513.388 46.5038 512.059 45.174 512.059 43.5344C512.059 41.8947 513.388 40.5649 515.028 40.5649C516.668 40.5649 517.997 41.8947 517.997 43.5344Z" fill="#091D21"/>
            <path d="M478.916 4.95764C480.285 4.95764 481.395 3.84784 481.395 2.47882C481.395 1.10981 480.285 0 478.916 0C477.547 0 476.438 1.10981 476.438 2.47882C476.438 3.84784 477.547 4.95764 478.916 4.95764Z" fill="#091D21"/>
            <path d="M278.572 22.7613C278.572 24.4009 277.242 25.7307 275.602 25.7307C273.963 25.7307 272.633 24.4009 272.633 22.7613C272.633 21.1217 273.963 19.7919 275.602 19.7919C277.242 19.7919 278.572 21.1217 278.572 22.7613Z" fill="#091D21"/>
            <path d="M87.6249 19.792C87.6249 20.8894 86.7341 21.7673 85.6496 21.7673C84.5651 21.7673 83.6743 20.8764 83.6743 19.792C83.6743 18.7075 84.5651 17.8167 85.6496 17.8167C86.7341 17.8167 87.6249 18.7075 87.6249 19.792Z" fill="#091D21"/>
            <path d="M340.903 220.628C340.903 221.725 340.012 222.603 338.927 222.603C337.843 222.603 336.952 221.713 336.952 220.628C336.952 219.544 337.843 218.653 338.927 218.653C340.012 218.653 340.903 219.544 340.903 220.628Z" fill="#091D21"/>
            <path d="M407.186 530.3C407.186 533.308 404.746 535.735 401.751 535.735C398.756 535.735 396.315 533.295 396.315 530.3C396.315 527.305 398.756 524.865 401.751 524.865C404.746 524.865 407.186 527.305 407.186 530.3Z" fill="#DBCCFF"/>
            <path d="M429.457 530.3C429.457 533.308 427.017 535.735 424.021 535.735C421.026 535.735 418.586 533.295 418.586 530.3C418.586 527.305 421.026 524.865 424.021 524.865C427.017 524.865 429.457 527.305 429.457 530.3Z" fill="#DBCCFF"/>
            <path d="M451.714 530.3C451.714 533.308 449.274 535.735 446.279 535.735C443.284 535.735 440.844 533.295 440.844 530.3C440.844 527.305 443.284 524.865 446.279 524.865C449.274 524.865 451.714 527.305 451.714 530.3Z" fill="#DBCCFF"/>
            <path d="M558.561 113.78C555.553 113.78 553.126 111.34 553.126 108.345C553.126 105.35 555.566 102.91 558.561 102.91C561.557 102.91 563.997 105.35 563.997 108.345C563.997 111.34 561.557 113.78 558.561 113.78Z" fill="#BCE1D3"/>
            <path d="M558.561 136.038C555.553 136.038 553.126 133.598 553.126 130.603C553.126 127.608 555.566 125.167 558.561 125.167C561.557 125.167 563.997 127.608 563.997 130.603C563.997 133.598 561.557 136.038 558.561 136.038Z" fill="#BCE1D3"/>
            <path d="M558.561 158.296C555.553 158.296 553.126 155.856 553.126 152.861C553.126 149.865 555.566 147.425 558.561 147.425C561.557 147.425 563.997 149.865 563.997 152.861C563.997 155.856 561.557 158.296 558.561 158.296Z" fill="#BCE1D3"/>
            <path d="M390.375 32.1601C390.375 35.1682 387.934 37.5954 384.939 37.5954C381.944 37.5954 379.504 35.1553 379.504 32.1601C379.504 29.1648 381.944 26.7247 384.939 26.7247C387.934 26.7247 390.375 29.1648 390.375 32.1601Z" fill="#BCE1D3"/>
            <path d="M412.632 32.1601C412.632 35.1682 410.192 37.5954 407.197 37.5954C404.202 37.5954 401.762 35.1553 401.762 32.1601C401.762 29.1648 404.202 26.7247 407.197 26.7247C410.192 26.7247 412.632 29.1648 412.632 32.1601Z" fill="#BCE1D3"/>
            <path d="M434.891 32.1601C434.891 35.1682 432.451 37.5954 429.456 37.5954C426.461 37.5954 424.021 35.1553 424.021 32.1601C424.021 29.1648 426.461 26.7247 429.456 26.7247C432.451 26.7247 434.891 29.1648 434.891 32.1601Z" fill="#BCE1D3"/>
            <path d="M200.915 51.9391C200.915 54.9473 198.475 57.3744 195.48 57.3744C192.485 57.3744 190.044 54.9344 190.044 51.9391C190.044 48.9439 192.485 46.5038 195.48 46.5038C198.475 46.5038 200.915 48.9439 200.915 51.9391Z" fill="#BCE1D3"/>
            <path d="M223.172 51.9391C223.172 54.9473 220.732 57.3744 217.737 57.3744C214.742 57.3744 212.302 54.9344 212.302 51.9391C212.302 48.9439 214.742 46.5038 217.737 46.5038C220.732 46.5038 223.172 48.9439 223.172 51.9391Z" fill="#BCE1D3"/>
            <path d="M245.43 51.9391C245.43 54.9473 242.99 57.3744 239.995 57.3744C237 57.3744 234.56 54.9344 234.56 51.9391C234.56 48.9439 237 46.5038 239.995 46.5038C242.99 46.5038 245.43 48.9439 245.43 51.9391Z" fill="#BCE1D3"/>
        </svg>
    );
};

export default HeaderBot;