import { ArrowUpRight } from '@styled-icons/bootstrap/ArrowUpRight';
import { DashLg } from '@styled-icons/bootstrap/DashLg';

import GGDVideo from '../../assets/ggd-demo-1.mp4';
import GGDImg from '../../assets/GGDImg.png';

const GGDArticle = () => {
    return (
        <div className='flex justify-center flex-wrap m-auto text-center'>
            <span className='pt-8 text-[1.1rem] md:text-[1.1rem] font-[500] w-full'>Gaming Industry</span>
            
            <div className='font-Sora w-full text-3xl md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-[4rem] font-[700] xl:px-72 py-4'>
                <div className='mt-1 md:mt-6 leading-snug'>
                    AI NPC in Video Games: 
                    <br/> Our Collaboration with Gaggle Studios
                </div>
            </div>

            <span className='md:py-4 md:mt-4 text-[1.1rem] md:text-[1.1rem] font-[500] w-[90%] md:w-[48%] opacity-[50%]'>
                We recently collaborated with Gaggle Studios to bring AI non-player characters (NPCs) to their game, Goose Goose Duck. This project marks the debut of Large Language Model (LLM)-based AI NPC in a popular game.
            </span>

            <div className='py-10 flex justify-center w-full'>
                <div
                onClick={() => window.open('https://gaggle.fun/goose-goose-duck', '_blank')}
                className='text-center bg-[#0E1218] text-white w-[221px] md:w-[221px] h-[56px] rounded-md flex-shrink-0 p-4 cursor-pointer'>
                    <span className='font-semibold text-sm sm:text-base lg:text-base'>
                        Visit Website
                        <ArrowUpRight className='w-4 relative left-1'/>
                    </span>
                </div>
            </div>

            <div className='flex justify-center py-20 w-full h-full'>
                <video className='w-fit h-fit md:h-[85vh] rounded-xl shadow-xl' controls>
                    <source src={GGDVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
           
            <div className='font-Sora flex text-left w-full md:w-[85vw] px-4 pb-20 flex-wrap text-xl md:text-2xl lg:text-3xl'>
                <div className='pb-10 w-full'>
                    <span className={`text-[1.1rem] font-medium`}> 
                        <DashLg className='w-6' />
                        &nbsp;
                        July 26th, 2023
                    </span>
                </div>

                <div>Meet the AI Bartender NPC</div>

                <div className='opacity-50 w-full font-[400] font-Inter text-[1rem] md:text-[.6em] leading-loose pt-6 pb-10'>
                    The first fruit of this collaboration is the bartender NPC, currently under beta testing. Unlike typical NPCs, the AI bartender is designed to engage players in a conversation that closely mirrors human interaction.
                </div>

                <div className='flex justify-center w-full pb-12'>
                    <img className='md:h-64 md:w-7/12 rounded-lg shadow-lg' src={GGDImg} />
                </div>

                <div>The Popularity of Goose Goose Duck</div>

                <div className='opacity-50 w-full font-[400] font-Inter text-[1rem] md:text-[.6em] leading-loose pt-6 pb-10'>
                    Goose Goose Duck is a well-received party game on Steam. With a peak of 702,845 concurrent players, it holds the 9th spot for all-time highest concurrent players on Steam. The game has managed to build a substantial user base of over 2 million players, making it an ideal ground for the introduction of AI innovations.
                </div>

                <div>Our Challenge and the Result</div>

                <div className='opacity-50 w-full font-[400] font-Inter text-[1rem] md:text-[.6em] leading-loose pt-6 pb-10'>
                    The objective wasn't simply to create AI NPCs but to design them with both short-term and long-term memory. The NPCs needed to have a semblance of self-awareness, something that would make them seem more than just programmed entities. With this successful project, we've managed to bring a new level of interaction to the gaming world.
                </div>

                <div className='bg-assistant-message p-2 md:p-4 rounded-md text-white text-center w-8/12 md:w-4/12 font-[400] font-Inter text-[1rem] md:text-[.3em] mt-10 cursor-pointer hidden'>
                    Read More
                </div>
            </div>
        </div>
    );
};

export default GGDArticle;