import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Google3 } from '@styled-icons/icomoon/Google3';
import { Github } from '@styled-icons/boxicons-logos/Github';
import { FacebookCircle } from '@styled-icons/boxicons-logos/FacebookCircle';

import fetchSignUpOrLogIn from '../../controller/fetchSignUpOrLogIn';

const LogInContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [clickedSignUpEP, setClickedSignUpEP] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [hoveringEP, setHoveringEP] = useState(false);
    const [hoveringGoogle, setHoveringGoogle] = useState(false);
    const [hoveringGitHub, setHoveringGitHub] = useState(false);
    const [hoveringFacebook, setHoveringFacebook] = useState(false);

    const [uri, setUri] = useState(process.env.REACT_APP_URI); 

    return (
        <div className='flex flex-wrap py-2 pt-10 lg:py-0 lg:pt-0 lg:h-screen'>
            <div className='flex justify-center items-center w-full lg:w-6/12'>
                <div className='shadow-lg border-2 rounded-xl w-4/6 p-8 mb-20'>
                    <div className='flex justify-center flex-wrap text-center'>
                        <span className='w-full font-Sora font-[600] text-[2.2rem] text-left'>Welcome back!</span>
                        <span className='w-full opacity-50 text-[1.2rem] text-left'>Sign in to your account below</span>

                        <div className={`${clickedSignUpEP < 1 && 'hidden'} flex justify-center mt-5 mb-[-4vh]`}>
                            <input
                            className='m-2 mb-4 rounded-lg border-sky-200 w-6/12 text-center'
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />

                            <input
                            className='m-2 mb-4 rounded-lg border-sky-200 w-6/12 text-center'
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div
                        onClick={async () => {
                            if (clickedSignUpEP < 1) {
                                setClickedSignUpEP(clickedSignUpEP + 1);
                                return;
                            };

                            fetchSignUpOrLogIn(false, uri, dispatch, navigate, null, true, email, password);
                        }}
                        onMouseEnter={() => setHoveringEP(true)}
                        onMouseLeave={() => setHoveringEP(false)}
                        className={`${hoveringEP && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 mt-10 w-full rounded-xl cursor-pointer`}>
                            { clickedSignUpEP < 1 ? 'Sign in with Email/Password' : 'Sign In' }
                        </div>

                        <div
                        onClick={async () => {
                            fetchSignUpOrLogIn(true, uri, dispatch, navigate, 'google');
                        }}
                        onMouseEnter={() => setHoveringGoogle(true)}
                        onMouseLeave={() => setHoveringGoogle(false)}
                        className={`${hoveringGoogle && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`}>
                            <Google3 className='w-6 mx-2' />

                            Sign in with Google
                        </div>

                        <div 
                        onClick={async () => {
                            fetchSignUpOrLogIn(true, uri, dispatch, navigate, 'github');
                        }}
                        onMouseEnter={() => setHoveringGitHub(true)}
                        onMouseLeave={() => setHoveringGitHub(false)}
                        className={`${hoveringGitHub && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`}>
                            <Github className='w-6 mx-2' />

                            Sign in with Github
                        </div>

                        <div
                        onClick={async () => {
                            fetchSignUpOrLogIn(true, uri, dispatch, navigate, 'facebook');
                        }}
                        onMouseEnter={() => setHoveringFacebook(true)}
                        onMouseLeave={() => setHoveringFacebook(false)}
                        className={`${hoveringFacebook && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`} >
                            <FacebookCircle className='w-6 mx-2' />

                            Sign in with Facebook
                        </div>
                    </div>
                    
                </div>

            </div>

            <div className='text-center w-full lg:w-6/12 bg-emerald-500 mt-4 rounded-tl-lg shadow-2xl'>
                <div className='w-full font-Sora font-[600] text-[2.5rem] mt-32'>
                    New to Mutual? 
                </div>
                    
                <div className='text-white w-full font-Sora font-[600] text-[2.5rem]'>
                    Getting started is easy.
                </div>

                <div className='text-black w-full font-Sora font-light text-[2rem] mt-10'>
                    Just create an account and
                    <br/> 
                    receive your <span className='text-white font-Inter font-[600]'>free</span> API key.
                </div>

                <div className='flex justify-center'>
                    <div 
                    onClick={() => {
                        navigate('/pricing');
                        window.scrollTo(0, 0);
                    }}
                    className='flex justify-center items-center text-center bg-[#0E1218] text-white w-full h-[60px] flex-shrink-0 p-4 mt-20 cursor-pointer '>
                        <span className='font-[600] text-sm sm:text-base lg:text-[1.2rem]'>
                            See Pricing Plans
                        </span>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default LogInContainer;