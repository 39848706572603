import { useEffect, useState } from 'react';

import Carousel from './Carousel.js';

import { Dot } from '@styled-icons/bootstrap/Dot';
import { KeyboardArrowLeft } from '@styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from '@styled-icons/material/KeyboardArrowRight';

const EnterpriseProcess = () => {
    const [hasClicked, setHasClicked] = useState(false);
    const [clickedSlide, setClickedSlide] = useState(false);
    const [slideDir, setSlideDir] = useState(false);

    useEffect(() => {
        if (!hasClicked) {
            const slideInterval = setInterval(() => {
                setClickedSlide(clicked => !clicked);
                setSlideDir(true);
            }, [5000]);
    
            return () => clearInterval(slideInterval);
        };
    }, [hasClicked]);

    return (
        <div 
        className='flex p-28 flex-wrap bg-[#0E1218] text-white'>
            <div className='font-Sora text-[3rem] text-left font-[600] w-full'>
                We are making significant and
                <br/>
                innovative advancements

                <div className='text-[1rem] opacity-50 text-left font-[400] mt-12 leading-10 w-full'>
                    Streamline operations, enhance customer satisfaction, and improve efficiency
                    <br/>
                    with custom AI agents.
                </div>
            </div>

            <div className='flex justify-center w-full h-[40vh] mt-28 overflow-x-hidden'>
                <Carousel clickedSlide={clickedSlide} slideDir={slideDir} />
            </div>

            <div className='flex justify-between w-full pt-12 px-8'>
                <div className='flex justify-center w-[4%]'>
                    <Dot 
                    className={`${clickedSlide ? 'opacity-50 w-10' : 'opacity-100 w-14'}`} 
                    />
                    <Dot 
                    className={`${!clickedSlide ? 'opacity-50 w-10' : 'opacity-100 w-14'}`} 
                    />
                </div>

                <div className='flex justify-evenly w-[12%]'>
                    <div 
                    onClick={() => {
                        setHasClicked(true);
                        setSlideDir(false);
                        setClickedSlide(clicked => !clicked);
                    }}
                    className='cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="66" height="56" viewBox="0 0 66 56" fill="none">
                            <path opacity="0.2" d="M10.444 1.78026C10.5504 1.1841 11.0689 0.75 11.6745 0.75H64C64.6904 0.75 65.25 1.30964 65.25 2V54C65.25 54.6904 64.6904 55.25 64 55.25H2.38878C1.6105 55.25 1.02143 54.5464 1.15825 53.7803L10.444 1.78026Z" stroke="white" stroke-width="1.5"/>
                            <KeyboardArrowLeft 
                            />
                        </svg>
                        
                    </div>
                    
                    <div 
                    onClick={() => {
                        setHasClicked(true);
                        setSlideDir(true);
                        setClickedSlide(clicked => !clicked);
                    }}
                    className='cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="66" viewBox="0 0 56 66" fill="none">
                            <path d="M0 11.6745C0 10.7055 0.694563 9.87597 1.64842 9.70564L53.6484 0.419925C54.8743 0.201021 56 1.14353 56 2.38878V64C56 65.1046 55.1046 66 54 66H2C0.89543 66 0 65.1046 0 64V11.6745Z" fill="white"/>
                            <KeyboardArrowRight
                            className='text-black'
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnterpriseProcess;