const HeaderEllipse = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 -12 348 117" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
            <path d="M346.886 24.5899C347.819 30.3999 344.086 36.8179 336.178 43.4822C328.298 50.1229 316.405 56.8915 301.366 63.394C271.295 76.3958 228.75 88.2888 180.863 95.9794C132.976 103.67 88.8464 105.697 56.2163 102.765C39.8973 101.298 26.4834 98.5939 16.9202 94.7545C7.32329 90.9015 1.76819 85.9751 0.835116 80.1651C-0.0979595 74.3551 3.63525 67.9371 11.5431 61.2729C19.4232 54.6321 31.3158 47.8635 46.3549 41.3611C76.4261 28.3592 118.971 16.4663 166.858 8.77564C214.745 1.08501 258.875 -0.941749 291.505 1.99037C307.824 3.45678 321.238 6.16112 330.801 10.0006C340.398 13.8535 345.953 18.7799 346.886 24.5899Z" stroke="url(#paint0_radial_128_16)" />

            <defs>
                <radialGradient id="paint0_radial_128_16" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(238.455 -1.77014) rotate(122.254) scale(107.012 405.082)">
                    <stop stopColor="#0E1218" stopOpacity="0.3"/>
                    <stop offset="1" stopColor="#0E1218" stopOpacity="0.06"/>
                </radialGradient>
            </defs>
            <foreignObject x="200" y="-12" width="80" height="80">
            <svg width="100%" height="100%" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0392 0.353084L20.0461 11.7546L31.9605 15.3754L20.559 20.3823L16.9382 32.2967L11.9313 20.8952L0.0169113 17.2744L11.4184 12.2675L15.0392 0.353084Z" fill="#299F73"/>
            </svg>
            </foreignObject>
        </svg>
    );
};

export default HeaderEllipse;