import Article from '../components/Article.js';

import { DashLg } from '@styled-icons/bootstrap/DashLg';
import { ArrowLeft } from '@styled-icons/bootstrap/ArrowLeft';
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight';

import GGDIcon from '../../assets/goose_goose_duck.png';

const ArticlesContainer = () => {
    const articlesData = {
        articles: [
            {
                img: GGDIcon,
                date: "October 26, 2022",
                title: "Goose Goose Duck",
                src: "ggd"
            }
        ]
    };

    return (
        <div
        className='w-fill py-12'>
            <div className='flex flex-wrap h-fit'>
                <div className='flex justify-center items-center md:ml-24 m-auto'>
                    <DashLg className='w-6 md:w-10' />

                    <span className='md:text-left md:text-[1.2em] ml-2 mr-4 md:ml-4 font-[500]'>
                        Blog
                    </span>
                </div>

                <span className='text-center md:text-left md:ml-24 mt-8 font-Sora font-semibold m-auto text-[2em] leading-10 md:leading-normal md:text-[3em] w-full'>
                    Our work
                </span>

                <span className='opacity-50 text-center md:text-left m-auto mt-6 md:mt-2 md:ml-24 leading-6 md:leading-normal font-light md:text-[1.2em] w-[85%] md:w-[55%]'>
                    We're helping companies and brands integrate our custom AI agents
                </span>
            </div>

            <div
            className='flex flex-col md:flex-row justify-start w-[88%] mt-12 m-auto overflow-x-auto'>
                {
                    articlesData.articles.map((article, i) => <Article img={article.img} date={article.date} title={article.title} src={article.src} key={i} />)
                }
            </div>

            <div className='flex flex-col md:flex-row items-center md:justify-between w-[88%] mt-20 m-auto hidden'>
                <div className='bg-assistant-message p-4 rounded-md text-white text-center w-fit md:w-[30%] lg:w-[26%] 2xl:w-[16%] cursor-pointer'>
                    View All Case Studies
                </div>

                <div className='hidden md:flex justify-evenly w-[10%]'>
                    <ArrowLeft className='w-[20%] cursor-pointer' />
                    <ArrowRight className='w-[20%] cursor-pointer' />
                </div>
            </div>
        </div>
    );
};

export default ArticlesContainer;