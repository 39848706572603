import { addMessageMutualBot, updateMessageMutualBot } from "../model/reducers/mutualBot";

import moment from "moment";

const fetchPresetMessage = async (input, dispatch, currentId, curNPC) => {
    const apiKeyChat = process.env.REACT_APP_CHAT_API_KEY;
    const chatUri = process.env.REACT_APP_CHAT_URI;

    const sendTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const senderMessageObj = {
      "time": sendTime,
      "content": input,
      "role": "user",
      "id": currentId,
    };

    currentId += 1;
    dispatch(addMessageMutualBot(senderMessageObj))

    try {
      const response = await fetch(`${chatUri}/api/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + apiKeyChat,
        },
        body: JSON.stringify({ cur_user: "User", content: input, cur_npc: curNPC })
      });

      if (!response.ok) throw new Error("HTTP error " + response.status);

      // create received object with new time
      const receivedTime = moment().format('YYYY-MM-DD HH:mm:ss');
      const receivedMessageObj = {
        "time": receivedTime,
        "content": "",
        "role": "assistant",
        "id": currentId,
      };

      dispatch(addMessageMutualBot(receivedMessageObj));

      let remainder = '';

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      // keep track of full message
      let fullMessage = '';

      reader.read().then(function processStream({ done, value }) {
        let chunk = decoder.decode(value, { stream: true });
        chunk = remainder + chunk;
        let endLineIndex;

        while ((endLineIndex = chunk.indexOf('\n')) >= 0) {
          let line = chunk.slice(0, endLineIndex);

          if (line.trim() !== '') {
            try {
              let data = JSON.parse(line);
              let content = data['content'];
              let error = data['error'];

              if (content == "[close]") return;
              
              if (error) {
                console.log(error);
              } else {
                fullMessage += content
                dispatch(updateMessageMutualBot({ id: currentId, newContent: fullMessage }))
              };
            } catch (err) {
              console.log(err);
            };
          };
          chunk = chunk.slice(endLineIndex + 1);
        };
        remainder = chunk;

        if (!done) return reader.read().then(processStream);
      }).catch(error => {
        console.error(error);
      });

    } catch (error) {
      console.error(error);
    };
  return;
};

export default fetchPresetMessage;