import IntroImg from '../../assets/intro_img.png'

import { ArrowUpRight } from '@styled-icons/bootstrap/ArrowUpRight';

const EnterpriseIntro = () => {
    return (
        <div 
        className='flex justify-center items-center flex-wrap text-center py-10'>
            <div className='font-Sora font-[700] text-[3.5rem] w-full py-20'>
                Transform Your Enterprise with 
                <br/>
                Custom AI Agents
            </div>

            <div className='opacity-50 text-[1.3rem] w-full'>
                Unleash Innovation and Efficiency Like Never Before. Customer support, automate monotonous tasks, AI co-pilot, etc. 
                <br/>
                You name it, we'll build it.
            </div>

            <div className='py-20 flex justify-center w-full'>
                <div
                onClick={() => window.open('https://hr9bw97u9xm.typeform.com/to/keN5Tv0E', '_blank')}
                className='text-center bg-[#0E1218] text-white w-[221px] md:w-[221px] h-[56px] rounded-md flex-shrink-0 p-4 cursor-pointer'>
                    <span className='font-semibold font-Sora text-sm sm:text-base lg:text-base'>
                        I'm interested
                        <ArrowUpRight className='w-4 relative left-1'/>
                    </span>
                </div>
            </div>

            <div className='flex justify-center items-center w-full h-fit mt-6'>
                <img src={IntroImg} alt="Stats Image" className='rounded-lg shadow-xl h-[65vh]' />
            </div>
        </div>
    );
};

export default EnterpriseIntro;