import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDqH9YYQpPzVDbXWielckIH1F5Pj9BQyNE",
  authDomain: "api-agent-mutual.firebaseapp.com",
  projectId: "api-agent-mutual",
  storageBucket: "api-agent-mutual.appspot.com",
  messagingSenderId: "1093746682953",
  appId: "1:1093746682953:web:2d88ca14ff57c5cc56e398",
  measurementId: "G-KPBK9VQW78"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);