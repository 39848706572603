import EnterpriseIntro from '../components/EnterpriseIntro'
import EnterpriseSolutions from '../components/EnterpriseSolutions';
import EnterpriseProcess from '../components/EnterpriseProcess';
import EnterpriseStats from '../components/EnterpriseStats';

import '../index.css';

const EnterpriseContainer = () => {
    return (
        <div className='text-center'>
            <EnterpriseIntro />
            <EnterpriseSolutions />
            <EnterpriseProcess />
            <EnterpriseStats />
        </div>
    );
};

export default EnterpriseContainer;