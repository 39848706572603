import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import GGDArticle from '../components/GGDArticle';

const ArticlePage = () => {
    const { article } = useParams();

    // Scroll to the top of the page when component is navigated to
    useEffect(() => { window.scrollTo(0, 0) }, []);

    return (
        <div>
            <div className={`${article !== 'ggd' && 'hidden'}`}>
                <GGDArticle />
            </div>
        </div>
    );
};

export default ArticlePage;