import { createSlice } from '@reduxjs/toolkit'

const initialChatsState = {
	messages: [],
	id: 1
};

const sendingChatSlice = createSlice({
	name: 'sendingChats',
	initialState: initialChatsState,
	reducers: {
		addMessage(state, action) {
			state.messages.push(action.payload);
			state.id = state.id + 1;
		},
		updateMessage(state, action) {
			const { id, newContent, thoughts, thought_id_count, thought_prompt} = action.payload;
			const existingMessage = state.messages.find(message => message.id === id);
			if (newContent) {
				existingMessage.content = newContent;
			}
			if (thoughts) {
				existingMessage.thoughts = thoughts;
			}
			if (thought_id_count){
				existingMessage.thought_id = thought_id_count;
			}
			if(thought_prompt){
				existingMessage.thought_prompt = thought_prompt
			}
		},
		clearMessagesSending(state) {
			state.messages = [];
			state.id = 1;
		},
	}
})

export const { addMessage, updateMessage, clearMessagesSending } = sendingChatSlice.actions

export default sendingChatSlice.reducer
