import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import ChatsReducer from './reducers/chatInfo'
import sendingDotMeReducer from './reducers/sendingDotMe'
import menuReducer from './reducers/menu';
import mutualBotReducer from './reducers/mutualBot';
import sessionReducer from './reducers/session';
import apiKeyReducer from './reducers/apiKey';

const rootReducer = combineReducers({
	chats: ChatsReducer,
	sendingChats: sendingDotMeReducer,
	menu: menuReducer,
	mutualBot: mutualBotReducer,
	session: sessionReducer,
	apiKey: apiKeyReducer
});

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['apiKey']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			serializableCheck: false,
		});
	},
})

const persistor = persistStore(store);
export { persistor };
export default store;