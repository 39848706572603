import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setError } from "../../model/reducers/menu";

const PaymentLinkBasic = () => {
    const dispatch = useDispatch();

    const clientId = useSelector(state => state.session.firebaseUid);

    const [isHovering, setIsHovering] = useState(false);

    return (
        <div 
        onClick={() => {
            if (!clientId) {
                window.scrollTo(0, 0);
                dispatch(setError('You must be logged in to purchase a subscription.'))
                return;
            };
            
            window.open(`https://buy.stripe.com/bIY4hZ4C24Ct1uEeUU?client_reference_id=${clientId}`, '_blank');
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`
            ${isHovering ? 'bg-[#279F71] text-white shadow-lg font-Sora' : 'bg-[#0E1218] text-white'} 
            flex m-auto items-center justify-center text-center w-[221px] md:w-[310px] h-[50px] rounded-md flex-shrink-0 p-4 my-10 cursor-pointer
        `}>
            Get Basic Plan Now
        </div>
    );
};

export default PaymentLinkBasic;