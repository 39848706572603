import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../../model/reducers/session';
import Cookies from 'js-cookie';

import SignUp from './SignUp';

import { setClickedSignUp } from '../../model/reducers/menu';

import { ArrowUpRight } from '@styled-icons/bootstrap/ArrowUpRight';
import { DashLg } from '@styled-icons/bootstrap/DashLg';

import HeaderTextAnimation from './HeaderTextAnimation';
import HeaderEllipse from './HeaderEllipse';

const HeaderText = () => {
    const dispatch = useDispatch();

    const clickedSignUp = useSelector(state => state.menu.clickedSignUp);
    const session = useSelector(state => state.session);

    const animationRef = useRef();

    const [isInViewport, setIsInViewport] = useState(false);

    /*
    The Intersection Observer below is created to observe the animationRef. When animationRef comes into view (entry.isIntersecting becomes true), the state isInViewport is set to true, which triggers the 'fade-in' animation.

    The animation is triggered when the parent element with animationRef is scrolled into view.
    */
    useEffect(() => {
        const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              setIsInViewport(entry.isIntersecting);
            });
          }
        );

        if (animationRef.current) observer.observe(animationRef.current);

        return () => {
          if (animationRef.current) observer.unobserve(animationRef.current);
        };
    }, []);

    if (clickedSignUp) return <SignUp />
    else return (
        <div
        ref={animationRef}
        id='header-text'
        className={`${isInViewport && 'fade-up'} text-[#0E1218] px-6 md:px-0`}>
            <div className='font-Sora flex w-full md:w-[47.53vw] text-3xl lg:text-4xl xl:text-6xl font-extrabold mt-[2vh]'>
                <div className='leading-tight md:leading-[1.4]'>

                    <div className='relative mb-[1vh]'>Revolutionize Customer Service
                        <div className='absolute top-[-20px] left-[10vw] md:left-[10vw]'>
                            <HeaderEllipse />
                        </div>
                    </div>

                    <span>
                        with AI
                    </span>
                </div>
            </div>

            <br />

            <span
            className='flex w-full md:w-[39.19vw] flex-col flex-shrink-0 text-[1.2rem] leading-[1.8] md:leading-[2.1] mb-14'>
                Mutual delivers AI-Assisted Customer Communication that lets you find out exactly what your customers ask for.
            </span>

            <div className='flex justify-between flex-wrap relative w-full md:w-[26.04vw]'>
                <div
                onClick={() => {
                    // window.open('https://use.mutual.info/dashboard?redirected_uri=https://mutual.info', '_blank');
                    if (session.token){
                            Cookies.set('use.mutual.info-token', session.token, { domain: '.mutual.info', expires: 1/24 })
                    }

                    window.open('https://use.mutual.info', '_blank');
                    // window.open('http://localhost:3000', '_blank');
                    // if (session.token) {
                    //     window.open('https://use.mutual.info/dashboard?redirected_uri=https://mutual.info', '_blank');

                    //     return;
                    // };

                    // dispatch(setClickedSignUp(true));
                }}
                className='flex flex-col items-center justify-center text-center bg-[#0E1218] text-white w-[221px] md:w-[221px] h-[56px] rounded-md flex-shrink-0 p-4 cursor-pointer'>
                    <span className={`font-semibold text-sm sm:text-base lg:text-base`}> {/*session.token && 'hidden'*/}
                    Go to Dashboard
                        {/* <span className='font-light text-sm sm:text-base lg:text-base'> It's free</span> */}
                    </span>

                    <span className={`hidden`}> {/*!session.token && 'hidden'*/}
                        <span className='font-[600]'>Dashboard</span> (Alpha)
                        <ArrowUpRight className='w-4 relative left-1'/>
                    </span>
                </div>

                <div
                onClick={() => window.open('https://mutual.gitbook.io/docs/', '_blank')}
                className='flex flex-col items-center justify-center text-center font-semibold flex-shrink-0 p-4 cursor-pointer'>
                    <span>
                        View Docs
                        <ArrowUpRight className='w-4 relative left-1'/>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default HeaderText;
