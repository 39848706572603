const { nanoid } = require('nanoid');

export function generateRandomUser() {
  return nanoid();
}

export function getRandomNPC() {
  const min = 1;
  const max = 10;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const npcName = "npc_" + randomNumber;
  return npcName;
}