import ScrollDown from "./ScrollDown";
import HeaderBot from "./HeaderBot";

const HeaderImg = () => {    
    return (
        <div
        className='flex h-fit'>
            <div
            className='hidden md:flex relative right-20 top-4 self-end'>
                <ScrollDown />
            </div>

            <div className='relative bottom-20 right-10 lg:bottom-14 xl:bottom-6 2xl:bottom-0'>
                <HeaderBot />
            </div>
        </div>
    );
};

export default HeaderImg;