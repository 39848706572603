import { useDispatch } from 'react-redux';

import { clearMessagesMutualBot } from '../../model/reducers/mutualBot';

import { Send } from '@styled-icons/boxicons-solid/Send';
import { CloseCircle } from '@styled-icons/ionicons-sharp/CloseCircle';

const InputBar = ({ handleKeyPress, handleClick, input, setInput }) => {
    const dispatch = useDispatch();

    return (
        <div className='flex justify-center w-full h-min mt-1'>
            <div className='w-9/12 md:w-11/12 relative'>
                <div
                className='cursor-pointer absolute left-[-1.5em] md:left-[-2.5em] top-1/2 transform -translate-y-1/2'
                id="chatbot-button"
                onClick={() => {
                    dispatch(clearMessagesMutualBot());
                }}>
                    <CloseCircle
                    className='w-[1.5em] md:w-[1.8em] text-white' 
                    />
                </div>

                <input
                className='w-full text-white ml-2 pr-6 py-3 bg-input-bar border-1 border-input-border rounded backdrop-filter backdrop-blur-sm text-sm md:text-[1em]'
                type="text"
                id="chatbot-input"
                placeholder="Ask me anything about Mutual"
                value={input}
                onChange={e => setInput(e.target.value)}
                onKeyDown={e=> {
                    handleKeyPress(e);
                }}
                />
                
                <div
                className='cursor-pointer absolute right-1 md:right-2 top-1/2 transform -translate-y-1/2'
                id="chatbot-button"
                onClick={() => {
                    handleClick();
                    setInput('');
                }}>
                    <Send 
                    className='w-[1.2em] text-white' 
                    />
                </div>
            </div>
        </div>
    );
};

export default InputBar;