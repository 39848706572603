import { CheckmarkCircle } from '@styled-icons/fluentui-system-filled/CheckmarkCircle';

const FreeTile = () => {
    return (
        <div
        className='text-[1.2rem] p-8 rounded-2xl shadow-2xl h-[76vh] my-4 bg-white'>
            <div className='border-b-2'>
                <div className='font-[700] font-Sora py-1'>Normal</div>
                <div className='opacity-50 pb-6'>Try it out for free</div>
                <div className='text-[2.5rem] font-[600] font-Sora py-1'>FREE</div>
                <div className='opacity-50 pb-10'>for lifetime</div>
            </div>

            <div className='pt-6 py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                1 agent
            </div>

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                100 permanent chats
            </div>

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                Create, update and talk to agent
            </div>
        </div>
    );
};

export default FreeTile;