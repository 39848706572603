import { useNavigate } from 'react-router-dom';

const Article = ({ img, date, title, src }) => {
    const navigate = useNavigate();

    return (
        <div className='mx-4 py-2 flex-shrink-0'>
            <img className='w-96 h-64 rounded-md shadow-lg' src={img} />

            <div className='mt-4'>
                <span className='opacity-50'>{ date }</span>

                <div className='flex justify-between'>
                    <span className='font-semibold text-[1.1em]'>{ title }</span>

                    <div
                    onClick={() => navigate('/' + src)} 
                    className='text-sky-400 text-[0.9em] cursor-pointer'>
                        VIEW CASE
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Article;