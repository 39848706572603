import PaymentLinkBasic from './PaymentLinkBasic.js';

import { CheckmarkCircle } from '@styled-icons/fluentui-system-filled/CheckmarkCircle';

const BasicTile = () => {
    return (
        <div
        className='text-[1.2rem] p-8 rounded-2xl shadow-2xl h-[76vh] my-4 bg-white'>
            <div className='border-b-2'>
                <div className='font-[700] font-Sora py-1'>Basic</div>
                <div className='opacity-50 pb-6'>Best value for personal use</div>
                <div className='text-[2.5rem] font-[600] font-Sora py-1'>$7.99</div>
                <div className='opacity-50 pb-10'>per month</div>
            </div>

            <div className='pt-6 py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                3 agents
            </div>

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                2000 chats per month in total with 
                <br/> 
                <span className='ml-8'>all agents</span>
            </div>
            

            <div className='py-2'>
                <CheckmarkCircle 
                className='w-6 mr-2 text-emerald-500'
                />

                No custom judge
            </div>

            <div>
                <PaymentLinkBasic />
            </div>
        </div>
    );
};

export default BasicTile;