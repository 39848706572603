import '../index.css';

const TypingBubble = ({ isTyping }) => {
    return (
        <div className={`${isTyping ? 'flex' : 'hidden'} self-end`}>
            <div className="px-4 flex flex-col justify-end relative">
                <div className='bg-user-message rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl max-w-[50vw] h-11 text-white flex items-center justify-center overflow-hidden p-4 mt-4'>
                    <div className='flex justify-center'>
                        <span className="bounce1">.</span>
                        <span className="bounce2">.</span>
                        <span className="bounce3">.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TypingBubble;