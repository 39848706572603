import { setApiKey } from '../model/reducers/apiKey';
import { setError } from '../model/reducers/menu';
import { setToken, setIsExpired, setFirebaseUid } from '../model/reducers/session';

const fetchUserData = async (token, dispatch) => {
    const uri = process.env.REACT_APP_URI;

    try {
      const req = await fetch(`${uri}/api/signup-with-provider`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "access_token": token })
      });
  
      const res = await req.json()

      dispatch(setToken(token));
      dispatch(setFirebaseUid(res.firebase_uid));
      dispatch(setApiKey(res.api_key));
      dispatch(setIsExpired(false));
    } catch (error) {
        dispatch(setError(error.message));
        dispatch(setIsExpired(true));
    };
};

export default fetchUserData;