import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import fetchMessage from '../../controller/fetchMessage.js';

import { useScroll } from '../../hooks/useScroll';

import PresetMessages from '../components/PresetMessages.js';
import TypingBubble from '../components/TypingBubble.js';
import Message from '../components/Message.js';
import InputBar from '../components/InputBar.js';

import '../index.css';

const MutualBot = () => {
    const dispatch = useDispatch();
    const messages = useSelector(state => state.mutualBot.messages);

    const buttonRef = useRef('');
    const scrollRef = useRef(null);
    const animationRef = useRef();

    // Custom hook for scrolling functionality
	  const { scrollToBottomIfAtBottom, scrollToBottom } = useScroll(scrollRef.current);

    // Retrieves the current chat ID from the store
	  let currentId = useSelector((state) => state.mutualBot.id);
    const curNPC = useSelector((state) => state.mutualBot.curNPC);

    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadSuccessFull, setUploadSuccessFull] = useState(false);
    const [isInViewport, setIsInViewport] = useState(false);

    const handleClick = () => {
      // Scrolls to the bottom of the chat window if already at the bottom
        scrollToBottomIfAtBottom();
      // Calls the fetchMessage function to send the user input and receive a response
        fetchMessage(input, dispatch, currentId, curNPC);
    };

    //Handling enter on Input bar
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.keyCode === 13 && e.shiftKey) {
        } else if (e.key === 'Enter' && !buttonRef.current.disabled) {
          // Scrolls to the bottom of the chat window if already at the bottom
            scrollToBottomIfAtBottom();
          // Calls the fetchMessage function to send the user input and receive a response
            fetchMessage(input, dispatch, currentId, curNPC);
          // Clears the user input
            setInput('');
        };
    };
    // Sets uploadSuccessFull to false after 1500ms
	  useEffect(() => {
	  	  let timeout;
	  	  if (uploadSuccessFull) {
	  	      timeout = setTimeout(() => {
	  	  	    setUploadSuccessFull(false);
	  	      }, 1500);
	  	  };

	  	  return () => clearTimeout(timeout);
	  }, [uploadSuccessFull]);

    // Scrolls to the bottom of the chat window when page is refreshed
    useEffect(() => { scrollRef.current.scrollTop = scrollRef.current.scrollHeight }, []);

    // Scrolls to the bottom of the chat window when the component mounts or when messages are updated
    useLayoutEffect(() => {
	      if (scrollRef) scrollToBottom();
	  }, [scrollRef, messages, scrollToBottom]);

    /*
    The Intersection Observer below is created to observe the animationRef. When animationRef comes into view (entry.isIntersecting becomes true), the state isInViewport is set to true, which triggers the 'fade-in' animation.

    The animation is independent of the scrolling related to scrollRef and is triggered when the parent element with animationRef is scrolled into view.
    */
    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            setIsInViewport(entry.isIntersecting);
          });
        }
      );

      if (animationRef.current) observer.observe(animationRef.current);

      return () => {
        if (animationRef.current) observer.unobserve(animationRef.current);
      };
    }, []);

    return (
      <div
      ref={animationRef}
      id='mutual-agent-container'
      className='flex flex-col w-[100%] py-20 items-center'>
        <div className={`${isInViewport && 'fade-up-chatbot'} text-white text-center leading-[1.2] md:leading-normal`}>
            <div className='font-[600] text-[2rem] md:text-[3rem]'>
              Ask about Mutual
              <span className='font-[300] text-[1.5rem] md:text-[2.2rem]'> to one of our agents</span>
            </div>

            <div className='font-[200] text-center mt-6 opacity-50'>
              This bot was trained with just 30 lines of text information about MUTUAL
            </div>
        </div>

        <div
        id='chatbot-container'
        className={`${isInViewport && 'fade-up-chatbot'} flex justify-center h-[70vh] py-10 flex-wrap`}>
          <div id='preset-messages-container' className={`${messages.length < 1 ? 'flex' : 'hidden'} w-[89vw]`}>
            <PresetMessages />
          </div>

          <div id='chatbot' className={`${messages.length > 0 ? 'flex' : 'hidden'} justify-center h-full`}>
            <div id='chatbot-body' className='flex flex-col items-start mb-4 overflow-auto w-[83vw]' ref={scrollRef}>
              {
                messages && messages.length > 0 &&
                messages.map(
                  (currMsg, i) =>
                  // Component for displaying messages
                  <Message
                  id={currMsg.id}
                  user={currMsg.role}
                  msg={currMsg.content}
                  key={i}
                  />
                )
              }

              <TypingBubble isTyping={input.length > 0} />
            </div>
          </div>

          <InputBar
          handleKeyPress={handleKeyPress}
          handleClick={handleClick}
          input={input}
          setInput={setInput}
          loading={loading}
          product={'sendingDotMe'}
          />
        </div>
      </div>
    );
};

export default MutualBot;
