import { createSlice } from '@reduxjs/toolkit';

// Define the initial state.
const initialState = {
    clickedHome: true,
    clickedSignUp: false,
    error: ''
};

// Use createSlice to generate the reducer and the action creators.
const menuSlice = createSlice({
    // The name is used as part of the action type.
    name: 'menu',
    // Initial state for this slice of the Redux store.
    initialState,
    // Define the reducer functions, which are dispatched in various components in order to alter the current state.
    reducers: {
        /*  
        Each function we define in this object will receive its own action type as applicable,
        and we can use these functions to handle the actions.
        The name of each function will be used as the part of the action type,
        and will be available as a function on the resulting action creator object. 
        */
        setClickedHome: state => {
            // Directly mutate the state and set "clickedHome" to true; ultimately will reset other states as necessary.
            state.clickedHome = true;
            state.error = '';
        },

        setClickedSignUp: (state, action) => {
            state.clickedSignUp = action.payload;
            state.error = '';
        },

        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

// Export the generated action creators as named exports.
export const { setClickedHome, setClickedSignUp, setError } = menuSlice.actions;

export default menuSlice.reducer;