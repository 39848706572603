import { useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import fetchSignUpOrLogIn from "../../controller/fetchSignUpOrLogIn.js";

import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';
import { Google3 } from '@styled-icons/icomoon/Google3';
import { Github } from '@styled-icons/boxicons-logos/Github';
import { FacebookCircle } from '@styled-icons/boxicons-logos/FacebookCircle';

import { setClickedSignUp } from "../../model/reducers/menu.js";

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clickedSignUpEP, setClickedSignUpEP] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [hoveringEP, setHoveringEP] = useState(false);
    const [hoveringGoogle, setHoveringGoogle] = useState(false);
    const [hoveringGitHub, setHoveringGitHub] = useState(false);
    const [hoveringFacebook, setHoveringFacebook] = useState(false);

    const [uri, setUri] = useState(process.env.REACT_APP_URI)

    return (
        <div className='flex justify-center w-full md:w-[47.53vw]'>
            <div className='flex text-center justify-center items-center flex-wrap w-10/12 shadow-lg pb-8 rounded-xl border-2'>
                <div className='flex justify-start w-full p-2'>
                    <CloseCircle 
                    onClick={() => dispatch(setClickedSignUp(false))}
                    className='w-6 cursor-pointer' />
                </div>

                <span className='font-Sora font-[600] text-xl pb-6 pr-6 w-5/6 text-center'>
                    Sign Up
                </span>

                <div className={`${clickedSignUpEP < 1 && 'hidden'} flex justify-evenly`}>
                    <input
                    className='m-1 mb-4 rounded-lg border-sky-200 w-6/12 text-center'
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />

                    <input
                    className='m-1 mb-4 rounded-lg border-sky-200 w-6/12 text-center'
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                <div className="w-5/6 flex justify-center flex-wrap">
                    <div
                    onClick={async () => {
                        if (clickedSignUpEP < 1) {
                            setClickedSignUpEP(clickedSignUpEP + 1);
                            return;
                        };

                        fetchSignUpOrLogIn(false, uri, dispatch, navigate, null, false, email, password);

                        dispatch(setClickedSignUp(false));
                    }}
                    onMouseEnter={() => setHoveringEP(true)}
                    onMouseLeave={() => setHoveringEP(false)}
                    className={`${hoveringEP && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`}>
                        { clickedSignUpEP < 1 ? 'Sign up with Email/Password' : 'Sign Up' }
                    </div>

                    <div
                    onClick={async () => {
                        fetchSignUpOrLogIn(true, uri, dispatch, navigate, 'google');

                        dispatch(setClickedSignUp(false));
                    }}
                    onMouseEnter={() => setHoveringGoogle(true)}
                    onMouseLeave={() => setHoveringGoogle(false)}
                    className={`${hoveringGoogle && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`}>
                        <Google3 className='w-6 mx-2' />

                        Sign up with Google
                    </div>

                    <div 
                    onClick={async () => {
                        fetchSignUpOrLogIn(true, uri, dispatch, navigate, 'github');

                        dispatch(setClickedSignUp(false));
                    }}
                    onMouseEnter={() => setHoveringGitHub(true)}
                    onMouseLeave={() => setHoveringGitHub(false)}
                    className={`${hoveringGitHub && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`}>
                        <Github className='w-6 mx-2' />

                        Sign up with Github
                    </div>

                    <div
                    onClick={async () => {
                        fetchSignUpOrLogIn(true, uri, dispatch, navigate, 'facebook');

                        dispatch(setClickedSignUp(false));
                    }}
                    onMouseEnter={() => setHoveringFacebook(true)}
                    onMouseLeave={() => setHoveringFacebook(false)}
                    className={`${hoveringFacebook && 'bg-[#0E1218] text-white'} border-2 p-3 m-1 w-full rounded-xl cursor-pointer`} >
                        <FacebookCircle className='w-6 mx-2' />

                        Sign up with Facebook
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;