import HeaderText from '../components/HeaderText';
import HeaderImg from '../components/HeaderImg';

const HeaderComponent = () => {
    return (
        <div 
        className='flex justify-center items-center bg-white pb-20 mt-[-1.5em]'>
            <HeaderText />
            
            <div className='hidden md:flex'>
                <HeaderImg />
            </div>
        </div>
    );
};

export default HeaderComponent;