import { useDispatch, useSelector } from "react-redux";

import { setError } from "../../model/reducers/menu";

import { CloseCircle } from '@styled-icons/evaicons-solid/CloseCircle';

const AuthError = () => {
    const dispatch = useDispatch();

    const error = useSelector(state => state.menu.error);

    if (error && error.length > 0) return (
        <div className='absolute flex justify-center text-center w-screen mt-28 z-50'>
            <div className='px-8 py-12 bg-black text-white text-[1.2rem] font-Sora font-[600] rounded-2xl shadow-2xl'>
                <div className='flex mt-[-4vh] ml-[-1vw]'>
                    <CloseCircle 
                    onClick={() => dispatch(setError(''))}
                    className='w-6 mr-4 cursor-pointer'
                    />
                </div>
                
                <div>
                    Oops, it looks like we ran into some trouble
                    <br/>
                    while trying to perform an <span className='font-light'>authentication</span> action.
                </div>

                <div className='mt-6'>
                    Reason given:
                    <br/>
                    <span className='font-light'>{ error }</span>
                </div>

                <div className='mt-4'>
                    (We're sorry for the inconvenience)
                </div>
            </div>
        </div>
    );
};

export default AuthError;